import { Input, Switch } from "antd";
import Search from "antd/es/input/Search";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PostApiCall from "../../PostApi";

export default function StoreMaster({ storeList, getStoreList }) {
  // const [storeList, setStoreList] = useState([]);
  const [editingStoreId, setEditingStoreId] = useState(null);
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState("");

  // Common function to edit  || edit and delete
  const handleEditStore = (storeId, action = 'Edit') => {
    const storeUpdate = storeList.find(
      (storedata) => storedata.FLD_Store_Id === storeId
    );
    setFormData({
      storename: storeUpdate.FLD_Store_Name,
      status: storeUpdate.FLD_Enabled_Flag === "Active",
    });

    if (action === "Delete") {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure you want to delete this store?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              handleUpdateStore(storeId, storeUpdate.FLD_Store_Name, action) // update form data then delete
            },
          },
          {
            label: "No",
            onClick: async () => {
              setEditingStoreId(null)
            },
          },
        ],
        closeOnClickOutside: true,
      });
    }
    setEditingStoreId(storeId);
  };

  const handleUpdateStore = (storeId, storeName = "", action = "Update") => {
    Notiflix.Loading.dots("");
    const updateStorePayload = action === "Delete" ?
      {
        storeid: storeId, storename: storeName, status: "Deleted",
      }
      :
      {
        storeid: storeId, storename: formData.storename, status: formData.status ? "Active" : "Inactive",
      }
    PostApiCall.postRequest(
      updateStorePayload,
      "AddStore"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Company updated successfully.");
          getStoreList();
        } else {
          Notiflix.Notify.failure("Company update failed!");
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // For update Status (active,inactive)
  const handleToggleChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      status: checked,
    }));
  };

  // Search data
  const onSearch = (value) => {
    setSearchValue(value);
  };

  const filteredStoreList = storeList.filter(
    (store) =>
      store.FLD_Store_Name &&
      store.FLD_Store_Name.toLowerCase().includes(searchValue.toLowerCase())
  );


  return (
    <div className="row pt-0 mt-0 mb-4 px-0">
      <div className="col-12 px-0">
        <div className="default-card default-card-border mt-2">
          <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
            <h4>List of Stores ({filteredStoreList.filter((store) => store.FLD_Enabled_Flag !== "Deleted").length} Nos.)</h4>
            <div className="search-box w-50">
              <Search
                placeholder="Search Store"
                allowClear
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="w-100"
              />
            </div>
          </div>
          <div className="row">
            {filteredStoreList?.length > 0 ? (
              filteredStoreList.filter((store) => store.FLD_Enabled_Flag !== "Deleted")?.map((store) => (
                <div className="col-lg-3 mb-4">
                  <div className="spare-card h-100">
                    <div className="row">
                      <div className="col-12 pb-3" key={store.FLD_Store_Id}>
                        <label>Store Name</label>
                        <Input
                          size="large"
                          placeholder="Store Name"
                          allowClear
                          className="input-box"
                          name="storename"
                          defaultValue={store.FLD_Store_Name}
                          disabled={editingStoreId !== store.FLD_Store_Id}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-12">
                        <div className="btn-border mt-2 pt-2">
                          <div className="d-flex align-items-center gap-2">
                            <p className="mb-0">Inactive</p>
                            <Switch
                              checked={
                                editingStoreId === store.FLD_Store_Id
                                  ? formData.status
                                  : store.FLD_Enabled_Flag === "Active"
                              }
                              disabled={editingStoreId !== store.FLD_Store_Id}
                              onChange={handleToggleChange}
                            />
                            <p className="mb-0">Active</p>
                          </div>
                          <p className="mb-0 mt-2">
                            Last Edited -
                            {store.FLD_Updated_on && (
                              <>
                                {moment(store.FLD_Updated_on).format(
                                  "DD-MM-YYYY"
                                )}
                              </>
                            )}
                          </p>
                          <div className="d-flex flex-column gap-3">
                            <Button
                              className="default-btn border-0 mt-3"
                              onClick={() =>
                                handleEditStore(store.FLD_Store_Id, "Delete") // update form data and delete
                              }>
                              Delete Store
                            </Button>
                            <Button
                              className="default-btn border-0"
                              onClick={() =>
                                editingStoreId === store.FLD_Store_Id
                                  ? handleUpdateStore(store.FLD_Store_Id)
                                  : handleEditStore(store.FLD_Store_Id)
                              }>
                              {editingStoreId === store.FLD_Store_Id
                                ? "Update Store"
                                : "Edit Store"}
                            </Button>

                            {editingStoreId === store.FLD_Store_Id && (
                              <Button
                                className="ml-2 default-btn border-0 mt-3"
                                onClick={() => setEditingStoreId(null)}>
                                Cancel
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
