import { Checkbox, Collapse, Drawer, Input, Modal, Select } from "antd";
import PostApiCall from "../../PostApi";
import "../ProductionManagement/ProductionManagement.css";
import { useEffect, useState } from "react";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
const { Panel } = Collapse;
export function RejectionJobCard() {
  const [filteredCardData, setFilteredCardData] = useState([]);
  const [deptDropdown, setDeptDropdown] = useState([]);
  const [open, setOpen] = useState(false);
  const [combinedLotData, setCombinedLotData] = useState([]);
  const [combinedRejections, setCombinedRejections] = useState(0);
  const [cardIds, setCardIds] = useState([]);
  const [combinedSNo, setCombinedSNo] = useState("");
  var login = JSON.parse(localStorage.getItem("LoginDetail"));

  function appendCardId(newId, rejectionData) {
    const isSelected = cardIds.includes(newId);
    if (isSelected == false) {
      setCardIds((prevIds) => [...prevIds, newId]);
      setCombinedLotData((prevData) => [...prevData, rejectionData]);
    } else {
      setCardIds((prevIds) => prevIds.filter((prevId) => prevId !== newId));
      setCombinedLotData((prevData) =>
        prevData.filter((data) => data !== rejectionData)
      );
    }
  }
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getRejectedCardData();
    getDepartmentData();
  }, []);

  useEffect(() => {
    const rejectionCount = combinedLotData.reduce(
      (sum, item) => sum + item.total_rejections,
      0
    );
    const combinedjobCardNos = combinedLotData
      .map((card) => card.FLD_Job_Card_Number)
      .join(",");
    setCombinedSNo(combinedjobCardNos);

    setCombinedRejections(rejectionCount);
  }, [combinedLotData]);

  const handleChangeinput = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  const getDepartmentData = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetDepartment").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setDeptDropdown(
              obj.data
                .sort((a, b) => a.Fld_Sequence - b.Fld_Sequence)
                .filter(
                  (department) => department.FLD_IsIncludeJobCard == "True"
                )
            );
          }
        })
    );
  };

  const getRejectedCardData = () => {
    try {
      PostApiCall.postRequest({}, "GetRejectedJobCards").then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setFilteredCardData(
              obj.data.filter((card) => card.total_rejections != null)
            );
          } else {
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  // send rejection lot to production line
  const addLotToProduction = (fieldsData) => {
    const formatIds = cardIds.join(",");
    PostApiCall.postRequest(
      {
        jobcardid: formatIds,
        deptseq: fieldsData.dept,
        repairedqty: combinedRejections - fieldsData.deadstock,
        deadstock: parseInt(fieldsData.deadstock),
        allotedby: fieldsData.allotedby,
      },
      "SendRejectionsToProduction"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Job Card sent to production");
          reset();
        } else {
          Notiflix.Notify.failure("Something went wrong");
        }
      })
    );
  };

  return (
    <>
      <div className="margin-left blank-section job-card-accordion">
        <div className="container-fluid pt-4 pb-2">
          <div className="row pt-4 mt-4 mb-4 px-0">
            <div className="col-12 mb-4 px-0 px-lg-2">
              <Collapse accordion>
                {filteredCardData.map((job, jobIndex) => {
                  // Get stages data from every jobcard
                  const stages = JSON.parse(job.approval_history) || [];
                  return (
                    <Panel
                      key={jobIndex}
                      header={
                        <>
                          <div className="d-lg-flex justify-content-between px-3 py-2">
                            <p className="card-number">
                              <div className="d-flex align-items-center">
                                <img
                                  src={job?.item_image}
                                  className="img-fluid"
                                />
                                {job.FLD_Job_Card_Number}
                              </div>
                            </p>
                          </div>
                          <div className="mt-2 border-top pt-2 px-3 inner-accordion-header">
                            <p>
                              <span>Total Quantity : </span>
                              {job.FLD_Item_Qty}
                            </p>
                            <p>
                              <span> Total Repairable Quantity : </span>
                              {job.total_rejections}
                            </p>
                            <p>
                              <span>Stage : </span>
                              {job.active_stage}
                            </p>
                          </div>
                          {/* create rejected items lot -  only available for rejection master manager */}
                          {login[0]?.FLD_User_Type == 5 ? (
                            <div className="tray-checkbox">
                              {(() => {
                                return (
                                  <>
                                    <Checkbox
                                      onChange={() =>
                                        appendCardId(job.FLD_Job_Card_Id, job)
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </>
                                );
                              })()}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      }
                    >
                      {stages.map((stage, stageIndex) => {
                        return (
                          <div className="px-3 py-2 mt-1" key={stageIndex}>
                            <p>
                              {stage.fld_pending_with} :{" "}
                              {stage.FLD_Repairable_Qty}
                            </p>
                          </div>
                        );
                      })}
                    </Panel>
                  );
                })}
              </Collapse>
            </div>

            {!filteredCardData.length && (
              <p className="text-center">No job card available at this stage</p>
            )}
            {cardIds.length > 0 && login[0]?.FLD_User_Type == 5 && (
              <div className="tray-shifting-floting-btn">
                <span
                  className="default-btn fs-5 w-100 d-block"
                  onClick={() => {
                    showDrawer();
                  }}
                >
                  Create Batch
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*  mapped data of added rejection lot */}
      <Drawer
        title="Create Batch"
        onClose={onClose}
        open={open}
        placement="bottom"
        className="job-card-page supervisor-filter-drawer tray-shifting-page "
      >
        <div className="container-fluid pb-2">
          <div className="row px-0">
            <div className="col-lg-4 px-0 px-lg-2">
              <div className="default-card default-card-border rejection-card">
                <div className="job-card-box p-0 border-0">
                  <div className="box border-0 mb-2">
                    <div className="d-flex align-items-center">
                      {/* <img src="" className="img-fluid" /> */}
                      <p className="card-number">{combinedSNo}</p>
                    </div>
                  </div>
                  <>
                    <div className="stage-input">
                      <div className="box">
                        <span>Total Rejected Items :</span>
                        <p>{combinedRejections}</p>
                      </div>
                      <div className="box">
                        <span>Dead Stock</span>
                        <div className="w-100">
                          <Input
                            type="number"
                            className="w-100 input-box"
                            allowClear
                            value={watch("deadstock")}
                            id="deadstock"
                            {...register("deadstock", {
                              required: "This field is required",
                            })}
                            onChange={handleChangeinput("deadstock")}
                          />
                          {errors.deadstock && (
                            <p className="text-danger">
                              {errors.deadstock.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="box">
                        <span>Repaired</span>
                        <Input
                          allowClear
                          disabled
                          className="input-box"
                          value={
                            combinedRejections - watch("deadstock")
                              ? combinedRejections - watch("deadstock")
                              : "Count of Repaired Items"
                          }
                        />
                      </div>
                      <div className="box">
                        <span>Alloted By</span>
                        <div className="w-100">
                          <Input
                            allowClear
                            className="input-box w-100"
                            value={watch("allotedby")}
                            id="allotedby"
                            {...register("allotedby", {
                              required: "This field is required",
                            })}
                            onChange={handleChangeinput("allotedby")}
                          />
                          {errors.allotedby && (
                            <p className="text-danger">
                              {errors.allotedby.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="box">
                        <span>
                          <label htmlFor="dept">
                            Assign to Stage{" "}
                            <span className="text-danger">*</span>
                          </label>
                        </span>
                        <div className="w-100">
                          <Select
                            size="large"
                            className="input-box input-box-select w-100"
                            id="dept"
                            placeholder="Choose A Dept."
                            value={watch("dept")}
                            {...register("dept", {
                              required: "This field is required",
                            })}
                            onChange={(value) => {
                              setValue("dept", value);
                              clearErrors("dept");
                            }}
                          >
                            {deptDropdown.map((dept) => (
                              <Select.Option
                                key={dept.FLD_Department_Id}
                                value={dept.Fld_Sequence}
                              >
                                {dept.FLD_Department_Name}
                              </Select.Option>
                            ))}
                          </Select>
                          {errors.dept && (
                            <p className="text-danger">{errors.dept.message}</p>
                          )}
                        </div>
                      </div>

                      <div className="mt-3 text-end">
                        <span
                          className="default-btn"
                          onClick={handleSubmit(addLotToProduction)}
                        >
                          Send to Production
                        </span>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
