import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Assembly from "../../assets/img/icons/Assembly.png";
import FlameTest1 from "../../assets/img/icons/flametest-1.png";
import FlameTest2 from "../../assets/img/icons/flametest-2.png";
import GasFilling from "../../assets/img/icons/gas-filling.png";
import Packaging from "../../assets/img/icons/Packaging.png";
import QualityCheck from "../../assets/img/icons/Quality-Check.png";
import TrayShifting from "../../assets/img/icons/Tray-Shifting.png";
import PostApiCall from "../../PostApi";
import "./SupervisorDashboard.css";

export default function RejectionDashboard() {
  const [jobCardData, setJobCartData] = useState([]);
  const [dashboardChipData, setDashboardChipData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getRejectedCards = () => {
      PostApiCall.postRequest({ stage: "all" }, "GetRejectionsForStage").then(
        (results) =>
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              setJobCartData(obj.data);
            }
          })
      );
    };

    getDashboardChipCount();
    getRejectedCards();
  }, []);

  dayjs.extend(customParseFormat);

  const getDashboardChipCount = () => {
    try {
      PostApiCall.postRequest(
        {
          filterbase: "RejectedCount",
        },
        "GetDashboardCount"
      ).then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setDashboardChipData(obj.data);
          } else {
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getStageCount = (stageName) => {
    const stageCount = dashboardChipData
      .filter((stage) => stage.FLD_Active_Stage === stageName)
      .reduce((total, stage) => total + stage.stage_count, 0);

    return stageCount ? stageCount : 0;
  };

  return (
    <>
      <div className="margin-left blank-section job-card-page supervisor-dashboard rejection-dashboard">
        <div className="container-fluid pt-4 pb-2">
          <div className="row pt-4 mt-4 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card default-card-border">
                <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
                  <h4>Rejection Count</h4>
                </div>
                <div className="jobcard-count-box">
                  <div className="jobcard-count-card">
                    <img
                      src={GasFilling}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Gas Filling")}</p>
                    <span>Gas Filling</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={FlameTest1}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Flame Testing 1")} </p>
                    <span>Flame Test 1</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={FlameTest2}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Flame Testing 2")}</p>
                    <span>Flame Test 2</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={Assembly}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Assembly")}</p>
                    <span>Assembly</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={QualityCheck}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Quality Check")}</p>
                    <span>Quality Check</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={TrayShifting}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Tray Shifting")}</p>
                    <span>Tray Shifting</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={Packaging}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Packaging")}</p>
                    <span>Packaging</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card">
                <div className="card-heading">
                  <h4>Rejected Items Count</h4>
                </div>
                <div className="job-card-main-box tray-shifting-job-card-box">
                  {jobCardData
                    .filter((jobCard) => jobCard.active_stage !== "Completed")
                    .map((jobcard, jobCardIndex) => {
                      return (
                        <div
                          key={jobCardIndex}
                          className="job-card-box"
                          onClick={() => navigate("/rejections")}
                        >
                          <div className="box border-0 mb-2">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <p className="card-number me-2">
                                <img
                                  src={jobcard.item_image}
                                  className="img-fluid"
                                />{" "}
                                {jobcard.item_name}
                              </p>
                              <div
                                className="jobcard-color-span"
                                style={{ background: `${jobcard.color}` }}
                              />
                            </div>
                          </div>
                          <div className="box">
                            <span> Total Repairable Quantity : </span>
                            <p>{jobcard.rejected_items}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
