import { DatePicker, Input, Select, Switch } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Button } from "react-bootstrap";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
export default function NotificationMaster() {
  const onChange = (e) => {
    console.log(e);
  };
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  return (
    <div className="row pt-0 mt-0 mb-4 px-0">
      <div className="col-12 px-0">
        <div className="default-card default-card-border mt-2">
          <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
            <h4>List of Notifications (25 Nos.)</h4>
            <div className="search-box w-50">
              <Search
                placeholder="Search Notification"
                allowClear
                onSearch={onSearch}
                className="w-100"
              />
            </div>
          </div>
          <div className="spare-card">
            <div className="row">
              <div className="col-lg-4 pb-3">
              <label>
                        Notification From <span className="text-danger">*</span>
                      </label>
                <Input
                  size="large"
                  // placeholder="Notification From"
                  allowClear
                  className="input-box"
                  onChange={onChange}
                />
              </div>
              <div className="col-lg-4 pb-3">
              <label>
                        Notification To <span className="text-danger">*</span>
                      </label>
                <Input
                  size="large"
                  // placeholder="Notification To"
                  allowClear
                  className="input-box"
                  onChange={onChange}
                />
              </div>
              <div className="col-lg-4 pb-3">
              <label>
                        Notification Type <span className="text-danger">*</span>
                      </label>
                <Select
                  size="large"
                  className="input-box"
                  placeholder="Notifiction Type"
                  defaultValue="Notifiction Type"
                  onChange={handleChange}
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "Notifiction Type",
                      label: "Notifiction Type",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </div>
              <div className="col-lg-12 pb-3">
              <label>
                        Notification Message <span className="text-danger">*</span>
                      </label>
                <Input
                  size="large"
                  // placeholder="Notification Message"
                  allowClear
                  className="input-box"
                  onChange={onChange}
                />
              </div>

              <div className="col-12">
                <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <p className="mb-0">Inactive</p>
                    <Switch onChange={onChange} />
                    <p className="mb-0">Active</p>
                    <p className="mb-0 ms-3">
                      Last Edited - Vishnu (29/08/2024)
                    </p>
                  </div>

                  <div className="d-flex gap-3">
                    <Button className="default-btn border-0 mt-3">
                      Delete Notification
                    </Button>
                    <Button className="default-btn border-0 mt-3">
                      Edit Notification
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spare-card">
            <div className="row">
              <div className="col-lg-4 pb-3">
              <label>
                        Notifiction From <span className="text-danger">*</span>
                      </label>
                <Input
                  size="large"
                  // placeholder="Notification From"
                  allowClear
                  className="input-box"
                  onChange={onChange}
                />
              </div>
              <div className="col-lg-4 pb-3">
              <label>
                        Notification To <span className="text-danger">*</span>
                      </label>
                <Input
                  size="large"
                  // placeholder="Notification To"
                  allowClear
                  className="input-box"
                  onChange={onChange}
                />
              </div>
              <div className="col-lg-4 pb-3">
              <label>
                        Notification Type <span className="text-danger">*</span>
                      </label>
                <Select
                  size="large"
                  className="input-box"
                  placeholder="Notifiction Type"
                  defaultValue="Notifiction Type"
                  onChange={handleChange}
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "Notifiction Type",
                      label: "Notifiction Type",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </div>
              <div className="col-lg-12 pb-3">
              <label>
                        Notification Message <span className="text-danger">*</span>
                      </label>
                <Input
                  size="large"
                  // placeholder="Notification Message"
                  allowClear
                  className="input-box"
                  onChange={onChange}
                />
              </div>

              <div className="col-12">
                <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <p className="mb-0">Inactive</p>
                    <Switch onChange={onChange} />
                    <p className="mb-0">Active</p>
                    <p className="mb-0 ms-3">
                      Last Edited - Vishnu (29/08/2024)
                    </p>
                  </div>

                  <div className="d-flex gap-3">
                    <Button className="default-btn border-0 mt-3">
                      Delete Notification
                    </Button>
                    <Button className="default-btn border-0 mt-3">
                      Edit Notification
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
