import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import GetApiCall from "../../GetApi";
export default function LineChart() {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 280,
        type: "area",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !0,
          columnWidth: "10%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",

        ],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["var(--color-theme)", "#9fb87382"],
      fill: {
        colors: ["#fff", "#9fb87382"],
      },
      stroke: {
        curve: "smooth",
      },
      responsive: [
        {
          breakpoint: 1000,
          yaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",

            ],
          },
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "50%",
                endingShape: "rounded",
              },
            },
          },
        },
      ],

    },
    series: [
      {
        name: "series1",
        data: [],
      },
      {
        name: "series2",
        data: [],
      },
    ],
  });

  useEffect(() => {
    GetApiCall.getRequest("GetYearlyRejections").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var month = [];
          var value = [];
          var deadStock = [];
          var sales = 0.0;
          obj.data?.sort((a, b) => a.month_order - b.month_order)?.map((data1, i) => {
            deadStock.push(data1.dead_stock);
            value.push(data1.repairable);
            month.push(data1.month);
            sales = sales + data1.total_sales;
          });


          // setTotalSales(sales);
          setChartData({
            options: {
              chart: {
                height: 280,
                // width: '100%',
                type: "area",
                stacked: !0,
                toolbar: {
                  show: 0,
                },
                zoom: {
                  enabled: !0,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: !0,
                  columnWidth: "10%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: 0,
              },
              xaxis: {
                categories: month,
              },
              legend: {
                position: "bottom",
                show: 0,
              },
              colors: ["var(--color-theme)", "#9fb87382"],
              fill: {
                colors: ["#9fb87382", "#9fb87382"],
              },
              stroke: {
                curve: "smooth",
              },
              // responsive: {
              //     breakpoint: undefined,
              //     options: {},
              // }
            },
            series: [
              {
                name: "Repairable",
                data: value,
              },
              {
                name: "Dead Stock",
                data: deadStock
              }
            ],
          });

          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  }, [])
  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="area"
      height={320}
    />
  );
}
