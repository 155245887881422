import React, { useState } from "react";
import { useEffect } from "react";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";

export default function Projections() {
  const [projections, setProjections] = useState([]);
  useEffect(() => {
    getprojections();
  }, []);
  const getprojections = () => {
    try {
      Notiflix.Loading.dots();
      PostApiCall.postRequest({}, "GetCartonProjections").then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setProjections(obj.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      Notiflix.Loading.remove();
    }
  };
  return (
    <div className="col-12 mb-4 px-0 px-lg-2 mb-2">
      <div className="default-card default-card-border ">
        <div className="card-heading mb-3 pb-3 d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Projections Based On The Available Data</h4>
        </div>
        <div className="projections-card">
          <div className="box">
            <p>
              <span>
                Total Boxes Projected :{" "}
                {projections[0]?.total_boxes_tray_shifting}{" "}
              </span>
              <br />
              <span>
                Total Cartons Projected :{" "}
                {projections[0]?.total_cartons_tray_shifting}{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
