import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { DatePicker, Input, message, Select, Switch, Upload } from "antd";
import Search from "antd/es/input/Search";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./EmployeeManagement.css";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};
export default function EmployeeMaster() {
  const onChange = (e) => {
    console.log(e);
  };
  // const [age, setAge] = React.useState("");

  const handleChangeImgUpload = (event) => {
    // setAge(event.target.value);
  };

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Employee Image
      </div>
    </button>
  );
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  return (
    <div className="margin-left">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Add New Employee</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-2 pb-3">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader w-100"
                    showUploadList={false}
                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                    beforeUpload={beforeUpload}
                    onChange={handleChangeImgUpload}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="User Name"
                        allowClear
                        className="input-box"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="User Pin"
                        allowClear
                        className="input-box"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-lg-4 pb-3">
                      <Select
                        size="large"
                        className="input-box"
                        placeholder="User Type"
                        defaultValue="User Type"
                        onChange={handleChange}
                        options={[
                          {
                            value: "jack",
                            label: "Jack",
                          },
                          {
                            value: "User Type",
                            label: "User Type",
                          },
                          {
                            value: "Yiminghe",
                            label: "yiminghe",
                          },
                        ]}
                      />
                    </div>
                    <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="Name"
                        allowClear
                        className="input-box"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="Mobile Number"
                        allowClear
                        className="input-box"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="Designation"
                        allowClear
                        className="input-box"
                        onChange={onChange}
                      />
                    </div>

                    <div className="col-lg-4 pb-3">
                      <TextArea
                        className="input-box input-box-textarea"
                        placeholder="Address"
                        allowClear
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 pb-3">
                          <DatePicker
                            className="input-box"
                            placeholder="Date of Joining"
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-lg-6 pb-3">
                          <Select
                            size="large"
                            className="input-box"
                            placeholder="City"
                            defaultValue="City"
                            onChange={handleChange}
                            options={[
                              {
                                value: "jack",
                                label: "Jack",
                              },
                              {
                                value: "City",
                                label: "City",
                              },
                              {
                                value: "Yiminghe",
                                label: "yiminghe",
                              },
                            ]}
                          />
                        </div>
                        <div className="col-lg-6 pb-3">
                          <Select
                            size="large"
                            className="input-box"
                            placeholder="State"
                            defaultValue="State"
                            onChange={handleChange}
                            options={[
                              {
                                value: "jack",
                                label: "Jack",
                              },
                              {
                                value: "State",
                                label: "State",
                              },
                              {
                                value: "Yiminghe",
                                label: "yiminghe",
                              },
                            ]}
                          />
                        </div>
                        <div className="col-lg-6 pb-3">
                          <Input
                            size="large"
                            placeholder="Pincode"
                            allowClear
                            className="input-box"
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-lg-6 pb-3">
                          <Select
                            size="large"
                            className="input-box"
                            placeholder="Country"
                            defaultValue="Country"
                            onChange={handleChange}
                            options={[
                              {
                                value: "jack",
                                label: "Jack",
                              },
                              {
                                value: "Country",
                                label: "Country",
                              },
                              {
                                value: "Yiminghe",
                                label: "yiminghe",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={onChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button className="default-btn border-0 mt-3">
                        Save Employee
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="default-card default-card-border mt-2">
              <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
                <h4>List of Employees (25 Nos.)</h4>
                <div className="search-box w-50">
                  <Search
                    placeholder="Search Employee"
                    allowClear
                    onSearch={onSearch}
                    className="w-100"
                  />
                </div>
              </div>
              <div className="spare-card">
                <div className="row">
                  <div className="col-lg-2 pb-3">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader w-100"
                      showUploadList={false}
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      beforeUpload={beforeUpload}
                      onChange={handleChangeImgUpload}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: "100%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="User Name"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="User Pin"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Select
                          size="large"
                          className="input-box"
                          placeholder="User Type"
                          defaultValue="User Type"
                          onChange={handleChange}
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "User Type",
                              label: "User Type",
                            },
                            {
                              value: "Yiminghe",
                              label: "yiminghe",
                            },
                          ]}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Name"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Mobile Number"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Designation"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>

                      <div className="col-lg-4 pb-3">
                        <TextArea
                          className="input-box input-box-textarea"
                          placeholder="Address"
                          allowClear
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-6 pb-3">
                            <DatePicker
                              className="input-box"
                              placeholder="Date of Joining"
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-lg-6 pb-3">
                            <Select
                              size="large"
                              className="input-box"
                              placeholder="City"
                              defaultValue="City"
                              onChange={handleChange}
                              options={[
                                {
                                  value: "jack",
                                  label: "Jack",
                                },
                                {
                                  value: "City",
                                  label: "City",
                                },
                                {
                                  value: "Yiminghe",
                                  label: "yiminghe",
                                },
                              ]}
                            />
                          </div>
                          <div className="col-lg-6 pb-3">
                            <Select
                              size="large"
                              className="input-box"
                              placeholder="State"
                              defaultValue="State"
                              onChange={handleChange}
                              options={[
                                {
                                  value: "jack",
                                  label: "Jack",
                                },
                                {
                                  value: "State",
                                  label: "State",
                                },
                                {
                                  value: "Yiminghe",
                                  label: "yiminghe",
                                },
                              ]}
                            />
                          </div>
                          <div className="col-lg-6 pb-3">
                            <Input
                              size="large"
                              placeholder="Pincode"
                              allowClear
                              className="input-box"
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-lg-6 pb-3">
                            <Select
                              size="large"
                              className="input-box"
                              placeholder="Country"
                              defaultValue="Country"
                              onChange={handleChange}
                              options={[
                                {
                                  value: "jack",
                                  label: "Jack",
                                },
                                {
                                  value: "Country",
                                  label: "Country",
                                },
                                {
                                  value: "Yiminghe",
                                  label: "yiminghe",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0">Inactive</p>
                        <Switch onChange={onChange} />
                        <p className="mb-0">Active</p>
                        <p className="mb-0 ms-3">
                          Last Edited - Vishnu (29/08/2024)
                        </p>
                      </div>

                      <div className="d-flex gap-3">
                        <Button className="default-btn border-0 mt-3">
                          Delete Employee
                        </Button>
                        <Button className="default-btn border-0 mt-3">
                          Edit Employee
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spare-card">
                <div className="row">
                  <div className="col-lg-2 pb-3">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader w-100"
                      showUploadList={false}
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      beforeUpload={beforeUpload}
                      onChange={handleChangeImgUpload}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: "100%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="User Name"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="User Pin"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Select
                          size="large"
                          className="input-box"
                          placeholder="User Type"
                          defaultValue="User Type"
                          onChange={handleChange}
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "User Type",
                              label: "User Type",
                            },
                            {
                              value: "Yiminghe",
                              label: "yiminghe",
                            },
                          ]}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Name"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Mobile Number"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Designation"
                          allowClear
                          className="input-box"
                          onChange={onChange}
                        />
                      </div>

                      <div className="col-lg-4 pb-3">
                        <TextArea
                          className="input-box input-box-textarea"
                          placeholder="Address"
                          allowClear
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-6 pb-3">
                            <DatePicker
                              className="input-box"
                              placeholder="Date of Joining"
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-lg-6 pb-3">
                            <Select
                              size="large"
                              className="input-box"
                              placeholder="City"
                              defaultValue="City"
                              onChange={handleChange}
                              options={[
                                {
                                  value: "jack",
                                  label: "Jack",
                                },
                                {
                                  value: "City",
                                  label: "City",
                                },
                                {
                                  value: "Yiminghe",
                                  label: "yiminghe",
                                },
                              ]}
                            />
                          </div>
                          <div className="col-lg-6 pb-3">
                            <Select
                              size="large"
                              className="input-box"
                              placeholder="State"
                              defaultValue="State"
                              onChange={handleChange}
                              options={[
                                {
                                  value: "jack",
                                  label: "Jack",
                                },
                                {
                                  value: "State",
                                  label: "State",
                                },
                                {
                                  value: "Yiminghe",
                                  label: "yiminghe",
                                },
                              ]}
                            />
                          </div>
                          <div className="col-lg-6 pb-3">
                            <Input
                              size="large"
                              placeholder="Pincode"
                              allowClear
                              className="input-box"
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-lg-6 pb-3">
                            <Select
                              size="large"
                              className="input-box"
                              placeholder="Country"
                              defaultValue="Country"
                              onChange={handleChange}
                              options={[
                                {
                                  value: "jack",
                                  label: "Jack",
                                },
                                {
                                  value: "Country",
                                  label: "Country",
                                },
                                {
                                  value: "Yiminghe",
                                  label: "yiminghe",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0">Inactive</p>
                        <Switch onChange={onChange} />
                        <p className="mb-0">Active</p>
                        <p className="mb-0 ms-3">
                          Last Edited - Vishnu (29/08/2024)
                        </p>
                      </div>

                      <div className="d-flex gap-3">
                        <Button className="default-btn border-0 mt-3">
                          Delete Employee
                        </Button>
                        <Button className="default-btn border-0 mt-3">
                          Edit Employee
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
