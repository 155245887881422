import React, { useEffect, useState } from "react";
import "./SideNavbar.css";
import { Link } from "react-router-dom";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { BsFillHouseDoorFill, BsHouseGearFill } from "react-icons/bs";
import { FaRectangleList } from "react-icons/fa6";
import { FaAddressCard } from "react-icons/fa";

export default function SupervisorSideNavbar({ handleCollapse }) {
  const parsedLoginData = JSON.parse(localStorage.getItem("LoginDetail"));
  let userRole;
  if (parsedLoginData) {
    userRole = parsedLoginData[0].FLD_Designation;
  }
  return (
    <Menu>
      <Link to={"/supervisor-dashboard"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <BsFillHouseDoorFill className="sidebar-icon" /> Dashboard
        </MenuItem>
      </Link>
      {userRole !== "Gas Filling" ? <Link to={"/active-job-card-list"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <FaAddressCard className="sidebar-icon" /> Active Job Cards
        </MenuItem>
      </Link> :
        <SubMenu
          label="Production Management"
          className="sidebar-menuitem"
          icon={<BsHouseGearFill className="sidebar-icon" />}
        >
          <MenuItem>
            {" "}
            <Link to={"/create-job-card"} onClick={() => handleCollapse()}>
              Job Card
            </Link>
          </MenuItem>
          <MenuItem>
            {" "}
            <Link to={"/active-job-card-list"} onClick={() => handleCollapse()}>
              Active Job Cards
            </Link>
          </MenuItem>
        </SubMenu>}
      <Link to={"/spares-request-form"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <FaRectangleList className="sidebar-icon" /> Spare Request
        </MenuItem>
      </Link>
    </Menu>
  );
}
