import moment from "moment";
import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { store } from "../../context/Store";

// For integrate company form using react UseForm
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";

export default function LoginPage() {
  const navigate = useNavigate();
  const { pushAlertId } = useContext(store);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        mobile: data.mobile,
        password: data.password,
        action: "Login",
        pushalertid: pushAlertId,
      },
      "AuthenticateUser"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          localStorage.setItem(
            "lastlogin",
            JSON.stringify(moment().format("lll"))
          );
          localStorage.setItem("access", JSON.stringify(obj.token));
          localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
          // window.location.href = "/dashboard"; // by default to dashboard
          navigate("/dashboard");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };
  return (
    <React.Fragment>
      <div className="account-pages">
        <Container fluid className="main-img">
          <Row className="justify-content-center align-items-center card-align">
            <Col md={7} lg={5} xl={4} className="login-card">
              <Card className="overflow-hidden login-card-shadow">
                <Card.Body className="pt-0 mb-4">
                  <div className="text-center mt-5 border-bottom">
                    {/* <img src={loginlogo} className="img-fluid w-50"></img> */}
                    <h1 className="mb-0">Shivaay </h1>
                    <h5>International</h5>
                    <p>An Integrated Production Management System</p>
                  </div>
                  {/* <h1 className=" mt-4">Sign in</h1> */}
                  <div className="p-2 mt-2">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobile"
                          maxLength={10}
                          {...register("mobile", {
                            required: "Mobile number is required",
                            pattern: {
                              value: /^\d{10}$/,
                              message: "Mobile number must be 10 digits",
                            },
                          })}
                          onChange={handleChange("mobile")}
                        />
                        {errors.mobile && (
                          <p className="text-danger">{errors.mobile.message}</p>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          {...register("password", {
                            required: "Password is required",
                          })}
                          onChange={handleChange("password")}
                        />
                      </Form.Group>
                      <div className="d-flex justify-content-end align-items-center">
                        <Button
                          type="submit"
                          className="border rounded px-3 py-2 border-white"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Sign in
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="row login-footer">
            <div className="col-md-6 text-center text-lg-start">
              <p className="mb-0 footer">
                Powered by{" "}
                <a href="http://globaltrendz.com/" target="_blank">
                  {" "}
                  Global Trendz.
                </a>
              </p>
            </div>
            <div className="col-md-6 text-center text-lg-end">
              <p className="mb-0 footer">
                Copyrights © {moment().format("YYYY")} Shivaay International.
                <br className="d-block d-lg-none" /> All rights reserved.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
