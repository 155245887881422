import dayjs from "dayjs";
import moment from "moment";
import { DatePicker, Input, Modal } from "antd";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsBox2Fill } from "react-icons/bs";
import { Link } from "react-router-dom";
import PostApiCall from "../../PostApi";
import "./SupervisorDashboard.css";
import cylinderIcon from "../../assets/img/available-cylinders.png";
import calendarIcon from "../../assets/img/calendar.png";
import CylindersIn from "../../assets/img/Cylinders-in.png";
import CylindersOut from "../../assets/img/Cylinders-out.png";
import { FaBoxes, FaCalendarAlt } from "react-icons/fa";

export default function GasSupervisor() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gasBankDetails, setGasBankDetails] = useState([]);
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const [requestedSpare, setRequestedSpare] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const formstate = true;

  useEffect(() => {
    GetGasBankDetails();
    GetSpareRequests();
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  // functions and handlers
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const GetGasBankDetails = () => {
    try {
      PostApiCall.postRequest(
        {
          whereClause: "order by FLD_Id desc",
          recordcount: "top 1 *",
        },
        "GetGasBankDetails"
      ).then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setGasBankDetails(obj.data);
          } else {
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const GetSpareRequests = () => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where FLD_Created_By = ${login[0].FLD_User_Id}`,
      },
      "GetSpareRequests"
    ).then((resultdes) => {
      resultdes.json().then((obj) => {
        try {
          setRequestedSpare(obj.data);
        } catch (error) {
          console.log(error);
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      });
    });
  }
  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    console.log(typeof data.date);
    PostApiCall.postRequest(
      {
        cylindersin: data.cylinderin,
        cylindersout: data.cylinderout,
        date: data.date,
        createdby: login[0].FLD_User_Id,
      },
      "UpdateGasBankDetails"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          reset({
            cylinderin: "",
            cylinderout: "",
            date: null,
          });
          GetGasBankDetails();
          setIsModalOpen(false);
          Notiflix.Notify.success("");
        } else {
          Notiflix.Notify.failure("User registration failed!");
        }
      })
    );
  };
  const targetPath =
    windowWidth > 568 ? "/spares-request-form" : "/spare-request-list";
  return (
    <>
      <div className="margin-left blank-section job-card-page supervisor-dashboard">
        <div className="container-fluid pt-4 pb-2">
          <div className="row mt-5 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card">
                <div className="card-heading d-flex justify-content-between align-items-lg-center">
                  <h4>Gas Bank</h4>
                  <div className="text-center mt-1">
                    <div
                      onClick={() => setIsModalOpen(true)}
                      className="default-btn"
                    >
                      Update Gas Stock
                    </div>
                  </div>
                </div>
                <div className="job-card-main-box gas-bank-card-box">
                  <div className="job-card-box">
                    <div className="box">
                      <img
                        src={cylinderIcon}
                        className="img-circle"
                        alt="Available Cylinders"
                      />{" "}
                      Available Cylinders -
                      <p>{gasBankDetails[0]?.FLD_Available_Cylinders}</p>
                    </div>
                    <div className="box">
                      <img
                        src={calendarIcon}
                        className="img-circle"
                        alt="Calendar Icon"
                      />{" "}
                      Last Updated on
                      <p>
                        {moment(gasBankDetails[0]?.FLD_Created_On).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>
                    <div className="box">
                      <img
                        src={CylindersIn}
                        className="img-circle"
                        alt="Calendar Icon"
                      />
                      <p>Cylinders In {gasBankDetails[0]?.FLD_Cylinders_In}</p>
                    </div>
                    <div className="box">
                      <img
                        src={CylindersOut}
                        className="img-circle"
                        alt="Calendar Icon"
                      />
                      <p>
                        Cylinders Out {gasBankDetails[0]?.FLD_Cylinders_Out}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card">
                <div className="card-heading d-flex justify-content-between align-items-lg-center">
                  <h4>Spare Request</h4>
                  {requestedSpare.length ? (
                    <div>
                      <Link
                        to={targetPath}
                        state={requestedSpare}
                        className="default-btn"
                      >
                        View All
                      </Link>
                    </div>
                  ) : (
                    <div className="text-center mt-1">
                      <Link
                        to="/spares-request-form"
                        state={formstate}
                        className="default-btn"
                      >
                        Request a Spare
                      </Link>
                    </div>
                  )}
                </div>
                <div className="job-card-main-box spare-card-list">
                  {requestedSpare
                    .sort(
                      (a, b) =>
                        b.FLD_Spares_Request_Id - a.FLD_Spares_Request_Id
                    )
                    .slice(0, 4)
                    .map((spares, index) => {
                      return (
                        <div key={index} className="job-card-box">
                          <div className="box">
                            <BsBox2Fill />
                            <p>{spares.items_name}</p>
                          </div>
                          <div className="box">
                            <FaBoxes />
                            <p>{spares.items_total_count}</p>
                          </div>
                          <div className="box">
                            <FaCalendarAlt />
                            <p>{spares.FLD_Spares_Stock_Request_Date}</p>
                          </div>
                          <div className="status-box">
                            <p>{spares.FLD_Enabled_Flag}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {requestedSpare.length ? (
                  <div className="text-center mt-3">
                    <Link
                      to="/spares-request-form"
                      state={formstate}
                      className="default-btn"
                    >
                      Request a Spare
                    </Link>
                  </div>
                ) : (
                  <p className="text-center">No Requests Found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={
          <>
            <h4>Update Cylinders Stock</h4>
          </>
        }
        open={isModalOpen}
        centered
        width={550}
        footer={null}
        // onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        zIndex={1000}
      >
        <div className="row">
          <p className="mb-3">
            Fields marked with <span className="text-danger">*</span> are
            mandatory.
          </p>
          <div className="col-md-4 mb-2 mb-lg-0">
            <label for="companyName">
              Cylinders In <span className="text-danger">*</span>
            </label>
            <Input
              size="large"
              allowClear
              className="input-box"
              name="cylinderin"
              id="cylinderin"
              {...register("cylinderin", {
                required: "This field is mandatory",
              })}
              onChange={handleChange("cylinderin")}
            />
            {errors.cylinderin && (
              <p className="text-danger">{errors.cylinderin.message}</p>
            )}
          </div>
          <div className="col-md-4 mb-2 mb-lg-0">
            <label for="companyName">
              Cylinders Out <span className="text-danger">*</span>
            </label>
            <Input
              size="large"
              allowClear
              className="input-box"
              name="cylinderout"
              id="cylinderout"
              {...register("cylinderout", {
                required: "This field is mandatory",
              })}
              onChange={handleChange("cylinderout")}
            />
            {errors.cylinderout && (
              <p className="text-danger">{errors.cylinderout.message}</p>
            )}
          </div>
          <div className="col-md-4">
            <label for="companyName">
              Date <span className="text-danger">*</span>
            </label>
            <DatePicker
              size="large"
              placeholder="Choose a Date"
              allowClear
              className="input-box w-100"
              name="Date"
              id="date"
              format={"DD-MM-YYYY"}
              defaultValue={dayjs}
              {...register("date", {
                required: "This field is mandatory",
              })}
              onChange={(date, dateString) => {
                setValue("date", dateString);
                clearErrors("date");
              }}
            />
            {errors.date && (
              <p className="text-danger">{errors.date.message}</p>
            )}
          </div>
        </div>
        <div className="text-end">
          <span
            className="default-btn border-0 mt-3 d-inline-block"
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </span>
        </div>
      </Modal>
    </>
  );
}
