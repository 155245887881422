import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, message, Select, Upload } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./StaffManagement.css";

// For integrate customer form using react UseForm
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";

// For get uploaded image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function StaffMasterListNew({
  toggleForm,
  userTypes,
  deptOptions,
}) {
  const [usersList, setUserList] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState("");

  // For image
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.floor(100000 + Math.random() * 900000))
  );

  useEffect(() => {
    getStaffList();
  }, []);

  const getStaffList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetStaffList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setUserList(obj.data);
          }
        })
    );
  };

  // Initilize UseForm
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // For edit forms
  const handleEditUser = (userId) => {
    const userUpdate = usersList.find((user) => user.FLD_User_Id === userId);
    setFormData({
      username: userUpdate.FLD_User_Name,
      userpin: userUpdate.FLD_User_Pin,
      name: userUpdate.FLD_Name,
      stafflogo: userUpdate.FLD_Image,
      usermobile: userUpdate.FLD_Mobile_Number,
      designation: userUpdate.FLD_Designation,
      userType: userUpdate.FLD_User_Type,
      date: userUpdate.FLD_Date_of_Joining,
      useraddress: userUpdate.FLD_Address,
      dept: userUpdate?.fle_dept ? userUpdate.fld_dept : "",
      pincode: userUpdate.FLD_Pincode,
      status: userUpdate.FLD_Status === "Active",
    });
    setEditingUserId(userId);
  };

  const handleUpdateUser = (userId) => {
    Notiflix.Loading.dots("");
    PostApiCall.postRequest(
      {
        userid: userId,
        username: formData.username,
        userpin: formData.userpin,
        name: formData.name,
        image: imageUrl === "" ? formData.stafflogo : imageUrl,
        usermobile: formData.usermobile,
        designation: formData.designation,
        // date: dayjs(formData.date).format("DD-MM-YY"),
        date: formData.date,
        useraddress: formData.useraddress,
        city: formData.city,
        state: formData.state,
        userType: formData.userType,
        country: formData.country,
        pincode: formData.pincode,
        status: formData.status ? "Active" : "Inactive",
      },
      "AddStaff"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("User updated successfully.");
          setEditingUserId(null);
          getStaffList();
        } else {
          Notiflix.Notify.failure("User update failed!");
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date, dateString) => {
    setFormData((prevData) => ({
      ...prevData,
      date: dateString,
    }));
  };

  // For edit and update status
  const handleToggleChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      status: checked,
    }));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const filteredUserList = usersList?.filter(
    (customer) =>
      customer.FLD_Name &&
      customer.FLD_Name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleDeleteUser = (userId) => {
    console.log(userId, "checkid");
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            Notiflix.Loading.dots("");
            try {
              const results = await PostApiCall.postRequest(
                {
                  userid: `${userId}`,
                  status: "Deleted",
                },
                "DeleteStaff"
              );
              if (results.status === 200 || results.status === 201) {
                Notiflix.Notify.success("User successfully deleted.");
                setUserList((prevList) =>
                  prevList.filter((user) => user.FLD_User_Id !== userId)
                );
                window.location.reload();
              } else {
                Notiflix.Notify.failure("Failed to delete the user.");
              }
            } catch (error) {
              Notiflix.Notify.failure("An error occurred: " + error.message);
            } finally {
              Notiflix.Loading.remove();
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  // Handel image on server

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}>
        Upload Employee Image
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "brandImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setImageUrl("");
  }, [setEditingUserId]);

  return (
    <div className="col-12 px-0">
      <div className="default-card default-card-border mt-0">
        <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
          <h4>List of Users ({filteredUserList.length} Nos.)</h4>
          <div className="d-flex gap-3 align-items-stretch justify-content-lg-end user-search-box">
            <div className="search-box ">
              <Search
                placeholder="Search User"
                allowClear
                className=""
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
            <div className="h-auto d-inline-block">
              <span
                onClick={() => toggleForm(false)}
                className="default-btn h-100 d-flex align-items-center">
                Add New User
              </span>
            </div>
          </div>
        </div>
        <div className="staff-list-box">
          {filteredUserList?.length > 0 ? (
            filteredUserList?.map((user) => (
              <div className="spare-card">
                <div className="row">
                  <div className="col-lg-4 pb-3" key={user.FLD_User_Id}>
                    <Upload
                      disabled={editingUserId !== user.FLD_User_Id}
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader w-100"
                      showUploadList={false}
                      customRequest={onaction}
                      beforeUpload={beforeUpload}>
                      {editingUserId !== user.FLD_User_Id ? (
                        <img
                          src={user.FLD_Image}
                          alt="avatar"
                          style={{
                            width: "100%",
                          }}
                        />
                      ) : user.FLD_Image !== "" && imageUrl === "" ? (
                        <>
                          {
                            <img
                              src={user.FLD_Image}
                              alt="avatar"
                              style={{
                                width: "100%",
                              }}
                            />
                          }
                        </>
                      ) : (
                        <>
                          {imageUrl === "" ? (
                            uploadButton
                          ) : (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{
                                width: "100%",
                              }}
                            />
                          )}
                        </>
                      )}
                    </Upload>
                  </div>
                  <div className="col-lg-8">
                    <div className="pb-3">
                      <label htmlFor="name">
                        Name<span className="text-danger">*</span>
                      </label>
                      <Input
                        size="large"
                        placeholder="Name"
                        allowClear
                        className="input-box"
                        name="name"
                        defaultValue={user.FLD_Name}
                        disabled={editingUserId !== user.FLD_User_Id}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="pb-3 d-none d-lg-block">
                      <label htmlFor="dept">
                        User Department<span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        className="input-box"
                        id="dept"
                        name="dept"
                        placeholder="Choose A Dept."
                        defaultValue={user?.FLD_Dept ? user.FLD_Dept : ""}
                        disabled={editingUserId !== user.FLD_User_Id}
                        onChange={(value) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            dept: value,
                          }));
                        }}>
                        {deptOptions.map((dept) => (
                          <Select.Option key={dept.fld_id} value={dept.fld_id}>
                            {dept.FLD_Designation}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-wrap gap-3 justify-content-end w-100">
                        <Button
                          className="default-btn border-0 mt-2"
                          onClick={() => handleDeleteUser(user.FLD_User_Id)}>
                          Delete User
                        </Button>
                        <Button
                          className="default-btn border-0 mt-0 mt-lg-2"
                          onClick={() =>
                            toggleForm(false, user)
                            // editingUserId === user.FLD_User_Id
                            //   ? handleUpdateUser(user.FLD_User_Id)
                            //   : handleEditUser(user.FLD_User_Id)
                          }>
                          {editingUserId === user.FLD_User_Id
                            ? "Update User"
                            : "Edit User"}
                        </Button>

                        {editingUserId === user.FLD_User_Id && (
                          <Button
                            className="ml-2 default-btn border-0 mt-3"
                            onClick={() => setEditingUserId(null)}>
                            Cancel
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No Data Available</p>
          )}
        </div>
      </div>
    </div>
  );
}
