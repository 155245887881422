import React, { useState, useEffect } from "react";
import "./MasterManagement.css";
import { Button } from "react-bootstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, message, Upload, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";

// For get uploaded image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function CompanyMasterList() {
  const [companyList, setCompanyList] = useState([]);
  const [editingCompanyId, setEditingCompanyId] = useState(null);
  const [formData, setFormData] = useState({});
  // const [searchValue, setSearchValue] = useState("");

  // For image
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const randomNumber = Math.floor(Math.floor(100000 + Math.random() * 900000));
  useEffect(() => {
    getCompanyList();
  }, []);

  const getCompanyList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetCompanyList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setCompanyList(obj.data);
          }
        })
    );
  };

  // For edit forms
  const handleEditCompany = (companyId) => {
    const companyUpdate = companyList.find(
      (company) => company.FLD_Company_Id === companyId
    );
    setFormData({
      companyname: companyUpdate.FLD_Company_Name,
      phone: companyUpdate.FLD_Phone_Number,
      mobile: companyUpdate.FLD_Mobile_Number,
      gstin: companyUpdate.FLD_GSTIN,
      address: companyUpdate.FLD_Address,
      city: companyUpdate.FLD_City,
      state: companyUpdate.FLD_State,
      country: companyUpdate.FLD_Country,
      pincode: companyUpdate.FLD_Pincode,
      status: companyUpdate.FLD_Enabled_Flag === "Active",
      companyLogo: companyUpdate.FLD_Company_Logo,
    });
    setEditingCompanyId(companyId);
  };

  const handleUpdateCompany = (companyId) => {
    Notiflix.Loading.dots("");
    PostApiCall.postRequest(
      {
        companyid: companyId,
        companyname: formData.companyname,
        companylogo: imageUrl === "" ? formData.companyLogo : imageUrl,
        phonenumber: formData.phone,
        mobilenumber: formData.mobile,
        gstin: formData.gstin,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        pincode: formData.pincode,
        status: formData.status ? "Active" : "Inactive",
      },
      "AddCompany"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Company updated successfully.");
          setEditingCompanyId(null);
          getCompanyList();
        } else {
          Notiflix.Notify.failure("Company update failed!");
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // For edit and update status
  const handleToggleChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      status: checked,
    }));
  };

  // Handel image on server
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Company Logo
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "companyImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setImageUrl("");
  }, [editingCompanyId]);

  return (
    <div className="margin-left company-profile-page">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-2 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border mt-0 blank-section">
              <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
                <h4>Company Profile</h4>
                {/* <div className="search-box w-50">
              <Search
                placeholder="Search Company"
                allowClear
                onSearch={(value) => setSearchValue(value)}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="w-100"
              />
            </div> */}
              </div>
              {companyList.length > 0 ? (
                companyList.map((company) => (
                  <div className="spare-card">
                    <div className="row">
                      <div
                        className="col-lg-2 pb-3"
                        key={company.FLD_Company_Id}
                      >
                        <Upload
                          disabled={editingCompanyId !== company.FLD_Company_Id}
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader w-100"
                          showUploadList={false}
                          customRequest={onaction}
                          beforeUpload={beforeUpload}
                        >
                          {editingCompanyId !== company.FLD_Company_Id ? (
                            <img
                              src={company.FLD_Company_Logo}
                              alt="avatar"
                              style={{
                                width: "100%",
                              }}
                            />
                          ) : company.FLD_Company_Logo !== "" &&
                            imageUrl === "" ? (
                            <>
                              {
                                <img
                                  src={company.FLD_Company_Logo}
                                  alt="avatar"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              }
                            </>
                          ) : (
                            <>
                              {imageUrl === "" ? (
                                uploadButton
                              ) : (
                                <img
                                  src={imageUrl}
                                  alt="avatar"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Upload>
                      </div>
                      <div className="col-lg-10">
                        <div className="row">
                          <div className="col-lg-4 pb-3">
                            <label for="companyName">
                              Company Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              size="large"
                              // placeholder="Company Name"
                              allowClear
                              className="input-box"
                              name="companyname"
                              defaultValue={company.FLD_Company_Name}
                              disabled={
                                editingCompanyId !== company.FLD_Company_Id
                              }
                              // {...register("companyname", {
                              //   required: "Company name is required",
                              // })}
                              onChange={handleInputChange}
                            />
                            {/* {errors.companyname && (
                          <p className="text-danger">
                            {errors.companyname.message}
                          </p>
                        )} */}
                          </div>
                          <div className="col-lg-4 pb-3">
                            <label for="phone">
                              {" "}
                              Phone Number{" "}
                              <span className="text-danger">*</span>{" "}
                            </label>
                            <Input
                              size="large"
                              placeholder="Phone Number"
                              allowClear
                              className="input-box"
                              name="phone"
                              defaultValue={company.FLD_Phone_Number}
                              disabled={
                                editingCompanyId !== company.FLD_Company_Id
                              }
                              onChange={handleInputChange}
                              // {...register("phone", {
                              //   required: "phone number is required",
                              //   pattern: {
                              //     value: /^\d{10}$/,
                              //     message: "phonenumber must be 10 digits",
                              //   },
                              // })}
                            />
                            {/* {errors.phone && (
                          <p className="text-danger">{errors.phone.message}</p>
                        )} */}
                          </div>

                          <div className="col-lg-4 pb-3">
                            <label for="mobile">
                              Mobile Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              size="large"
                              placeholder="Mobile Number"
                              allowClear
                              className="input-box"
                              name="mobile"
                              defaultValue={company.FLD_Mobile_Number}
                              disabled={
                                editingCompanyId !== company.FLD_Company_Id
                              }
                              onChange={handleInputChange}
                              // {...register("mobile", {
                              //   required: "mobile number is required",
                              //   pattern: {
                              //     value: /^\d{10}$/,
                              //     message: "mobile must be 10 digits",
                              //   },
                              // })}
                            />
                            {/* {errors.mobile && (
                          <p className="text-danger">{errors.mobile.message}</p>
                        )} */}
                          </div>
                          <div className="col-lg-4 pb-3">
                            <label for="gstin">
                              GSTIN <span className="text-danger">*</span>{" "}
                            </label>
                            <Input
                              size="large"
                              placeholder="GSTIN"
                              allowClear
                              className="input-box"
                              name="gstin"
                              defaultValue={company.FLD_GSTIN}
                              disabled={
                                editingCompanyId !== company.FLD_Company_Id
                              }
                              onChange={handleInputChange}
                              // {...register("gstin", {
                              //   required: "GSTIN is required",
                              //   pattern: {
                              //     value: /^[0-9A-Z]{15}$/,
                              //     message: "Invalid GSTIN format",
                              //   },
                              // })}
                            />
                            {/* {errors.gstin && (
                          <p className="text-danger">{errors.gstin.message}</p>
                        )} */}
                          </div>
                          <div className="col-lg-4 pb-3">
                            <label for="pincode">
                              Pincode <span className="text-danger">*</span>
                            </label>
                            <Input
                              size="large"
                              placeholder="Pincode"
                              allowClear
                              className="input-box"
                              name="pincode"
                              defaultValue={company.FLD_Pincode}
                              disabled={
                                editingCompanyId !== company.FLD_Company_Id
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-lg-4 pb-3">
                            <label for="address">
                              Address <span className="text-danger">*</span>
                            </label>
                            <TextArea
                              className="input-box input-box-textarea"
                              placeholder="Address"
                              allowClear
                              name="address"
                              defaultValue={company.FLD_Address}
                              disabled={
                                editingCompanyId !== company.FLD_Company_Id
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center gap-2">
                            <p className="mb-0">Inactive</p>
                            <Switch
                              checked={
                                editingCompanyId === company.FLD_Company_Id
                                  ? formData.status
                                  : company.FLD_Enabled_Flag === "Active"
                              }
                              disabled
                              onChange={handleToggleChange}
                            />
                            <p className="mb-0">Active</p>
                            <p className="mb-0 ms-3">
                              Last Edited -
                              {company.FLD_Updated_on && (
                                <>
                                  {moment(company.FLD_Updated_on).format(
                                    "DD-MM-YYYY"
                                  )}
                                </>
                              )}
                            </p>
                          </div>

                          <div className="d-flex gap-3">
                            {/* <Button
                          className="default-btn border-0 mt-3"
                          onClick={() =>
                            handleDeleteCompany(company.FLD_Company_Id)
                          }
                        >
                          Delete Company
                        </Button> */}
                            <Button
                              className="default-btn border-0 mt-3"
                              onClick={() =>
                                editingCompanyId === company.FLD_Company_Id
                                  ? handleUpdateCompany(company.FLD_Company_Id)
                                  : handleEditCompany(company.FLD_Company_Id)
                              }
                            >
                              {editingCompanyId === company.FLD_Company_Id
                                ? "Update Company"
                                : "Edit Company"}
                            </Button>
                            {editingCompanyId === company.FLD_Company_Id && (
                              <Button
                                className="ml-2 default-btn border-0 mt-3"
                                onClick={() => setEditingCompanyId(null)}
                              >
                                Cancel
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
