import { createContext, useEffect, useState } from "react";

export const store = createContext();

const Provider = ({ children }) => {
  const [brandUniqId, setBrandId] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [loginDetails, setLoginDetails] = useState([]);
  const [lotIds, setLotIds] = useState("");
  const [pushAlertId, setPushAlertId] = useState("");

  useEffect(() => {
    if (!window.pushalertbyiw) {
      window.pushalertbyiw = [];
    }
    window.pushalertbyiw.push(["onReady", onPAReady]);
    function onPAReady() {
      if (window.PushAlertCo && window.PushAlertCo.subs_id) {
        setPushAlertId(window?.PushAlertCo?.subs_id);
        console.log("Subscribed User ID:", window.PushAlertCo.subs_id);
      }
      //  else {
      //   console.log("User is not subscribed");
      // }
    }
  }, []);

  return (
    <store.Provider
      value={{
        brandUniqId,
        setBrandId,
        brandList,
        setBrandList,
        loginDetails,
        setLoginDetails,
        lotIds,
        setLotIds,
        pushAlertId,
        setPushAlertId,
      }}
    >
      {children}
    </store.Provider>
  );
};

export default Provider;
