import React, { useState, useContext, useEffect } from "react";
import "./ItemManagement.css";
import { Button } from "react-bootstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, message, Upload, Select, Switch } from "antd";

// For integrate company form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import ItemMasterList from "./ItemMasterList";
import { store } from "../../context/Store";

// For image upload preview image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must be smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function ItemMaster() {
  const [status, setStatus] = useState(false);
  const { brandUniqId } = useContext(store);
  const [itemList, setItemList] = useState([]); // from api

  useEffect(() => {
    getItems();
  }, []);
  const getItems = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetitemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setItemList(obj.data);
          }
        })
    );
  };
  // For upload images
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const randomNumber = Math.floor(Math.floor(100000 + Math.random() * 900000));

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  // Send save data
  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        itemid: null,
        itemname: data.itemname,
        brandid: data.brandid,
        image: imageUrl,
        itemtype: data.itemtype,
        unit: data.unit,
        weight: "200", // removed field data
        stockinhand: 1, // removed field data
        status: status ? "Active" : "Inactive",
      },
      "AddItem"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("Item saved successfully");
          getItems();
          reset({
            itemname: "",
            itemtype: "",
            brandid: null,
          });
          setImageUrl("");
          setStatus(false);
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };

  // Handle Image on server
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Item Image
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "itemMasterImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="margin-left">
      <div className="container-fluid pt-4 pb-0">
        <div className="row pt-4 mt-4 mb-2 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Add New Item</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-2 pb-3">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader w-100"
                    showUploadList={false}
                    customRequest={onaction}
                    beforeUpload={beforeUpload}
                  >
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                    {imageUrl === "" && uploadButton}
                  </Upload>
                </div>

                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-lg-4 pb-3">
                      <label for="itemname">
                        Item Name <span className="text-danger">*</span>{" "}
                      </label>
                      <Input
                        size="large"
                        // placeholder="Item Name"
                        allowClear
                        className="input-box"
                        value={watch("itemname") || ""}
                        id="itemname"
                        {...register("itemname", {
                          required: "Item name is required",
                        })}
                        onChange={handleChange("itemname")}
                      />
                      {errors.itemname && (
                        <p className="text-danger">{errors.itemname.message}</p>
                      )}
                    </div>
                    <div className="col-lg-4 pb-3">
                      <label for="itemtype">
                        Item Type <span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        name="itemtype"
                        className="input-box"
                        value={watch("itemtype") || ""}
                        // placeholder="Choose Item Type"
                        {...register("itemtype", {
                          required: "Item Type is required",
                        })}
                        onChange={(value) => {
                          setValue("itemtype", value);
                          clearErrors("itemtype");
                        }}
                        options={[
                          // { value: "", label: "Choose Item Type" },
                          { value: "Electric", label: "Electric" },
                          { value: "Windproof", label: "Windproof" },
                          { value: "Flint", label: "Flint" },
                        ]}
                      />
                      {errors.itemtype && (
                        <p className="text-danger">{errors.itemtype.message}</p>
                      )}
                    </div>
                    <div className="col-lg-4 pb-3">
                      <label for="brandid">
                        Brand <span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        className="input-box"
                        placeholder="Choose Brand"
                        value={watch("brandid") || ""}
                        {...register("brandid", {
                          required: "Brand is required",
                        })}
                        onChange={(value) => {
                          setValue("brandid", value);
                          clearErrors("brandid");
                        }}
                        options={[
                          ...(brandUniqId?.map((brand) => ({
                            value: brand?.FLD_Brand_Id,
                            label: brand?.FLD_Brand_Name,
                          })) || []),
                        ]}
                      />
                      {errors.brandid && (
                        <p className="text-danger">{errors.brandid.message}</p>
                      )}
                    </div>
                    {/* <div className="col-lg-4 pb-3">
                      <label for="colorid">
                        Color <span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        className="input-box"
                        placeholder="Choose Item Color"
                        value={watch("colorid") || ""}
                        {...register("colorid", {
                          required: "color is required",
                        })}
                        // onChange={(value) => {
                        //   setValue("colorid", value);
                        //   clearErrors("colorid");
                        // }}
                        options={[
                          ...(colors?.map((color) => ({
                            value: color?.FLD_id,
                            label: color?.FLD_Color,
                          })) || []),
                        ]}
                      />
                      {errors.colorid && (
                        <p className="text-danger">{errors.colorid.message}</p>
                      )}
                    </div> */}
                    {/* <div className="col-lg-4 pb-3">
                      <label for="colorid">
                        Brand <span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        className="input-box"
                        placeholder="Choose color"
                        value={watch("colorid") || ""}
                        {...register("colorid", {
                          required: "Brand is required",
                        })}
                        onChange={(value) => {
                          setValue("colorid", value);
                          clearErrors("colorid");
                        }}
                        options={[
                          ...(colors?.map((color) => ({
                            value: color?.FLD_Brand_Id,
                            label: color?.FLD_Brand_Name,
                          })) || []),
                        ]}
                      />
                      {errors.brandid && (
                        <p className="text-danger">{errors.brandid.message}</p>
                      )}
                    </div> */}

                    {/* <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="Item Unit"
                        allowClear
                        className="input-box"
                        id="unit"
                        {...register("unit", {
                          required: "Item unit is required",
                        })}
                        onChange={handleChange("unit")}
                      />
                      {errors.unit && (
                        <p className="text-danger">{errors.unit.message}</p>
                      )}
                    </div> */}

                    {/* <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="Weight"
                        allowClear
                        className="input-box"
                        id="weight"
                        {...register("weight", {
                          required: "Weight is required",
                        })}
                        onChange={handleChange("weight")}
                      />
                      {errors.weight && (
                        <p className="text-danger">{errors.weight.message}</p>
                      )}
                    </div> */}

                    {/* <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="Stock In Hand"
                        allowClear
                        className="input-box"
                        id="stockinhand"
                        {...register("stockinhand", {
                          required: "stock in hand is required",
                        })}
                        onChange={handleChange("stockinhand")}
                      />
                      {errors.stockinhand && (
                        <p className="text-danger">
                          {errors.stockinhand.message}
                        </p>
                      )}
                    </div> */}
                  </div>
                </div>

                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={handleStatusChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save Item
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* **** Item Master List **** */}
        <ItemMasterList itemList={itemList} getItems={getItems} />
      </div>
    </div>
  );
}
