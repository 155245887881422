import {
  Checkbox,
  Collapse,
  Drawer,
  Input,
  Modal,
  Select,
  Statistic,
  notification,
} from "antd";
import { IoIosWarning } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import PostApiCall from "../../PostApi";
import "./ProductionManagement.css";
import { useEffect, useState, useContext } from "react";
import Notiflix from "notiflix";
import { MdWarning } from "react-icons/md";
import Projections from "./Projections";
import { store } from "../../context/Store";
import { Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { DatePicker } from "antd";
import moment from "moment";
import { IoMdRefresh } from "react-icons/io";


const { RangePicker } = DatePicker;
const { Countdown } = Statistic;
const { Panel } = Collapse;
export function JobCardAccordion() {
  const [jobCardData, setJobCartData] = useState([]);
  const [filteredCardData, setFilteredCardData] = useState([]);
  const [deadstock, setDeadStock] = useState(null);
  const [repairable, setRepairable] = useState(null);
  const [isCritical, setIsCritical] = useState(false);
  const [deptDropdown, setDeptDropdown] = useState([]);
  const [open, setOpen] = useState(false);
  const [lotData, setLotData] = useState([]);
  const [viewLot, setViewLot] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [cardIds, setCardIds] = useState([]);
  const [lotCardIds, setLotCardIds] = useState([]);
  const { lotIds, setLotIds } = useContext(store);
  const [warehouseId, setWarehouseId] = useState(null);
  const [openWrehouseDropdownModal, setWarehouseDropdownModal] =
    useState(false);
  var login = JSON.parse(localStorage.getItem("LoginDetail"));

  // states to update stage details
  const [isStageEdit, setIsStageEdit] = useState(false);
  const [updQtyIn, setUpdQtyIn] = useState(null);
  const [updDeadStk, setUpdDeadStk] = useState(null);
  const [updRepairable, setUpdRepairable] = useState(null);
  const [updQtyOut, setUpdQtyOut] = useState(null);

  // states for date filter
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)



  function appendCardId(newId, stageApprovalId, criticalStatus, itemId) {
    const isSelected = cardIds.includes(newId);
    if (isSelected == false) {
      setCardIds((prevIds) => [...prevIds, newId]); // add ids to the array
      setLotCardIds((prevIds) => [...prevIds, itemId]); // add ids to the array
    } else {
      setCardIds((prevIds) => prevIds.filter((prevId) => prevId !== newId));
      setLotCardIds((prevIds) => prevIds.filter((prevId) => prevId !== itemId));
    }

    // stage status change
    UpdateJobCardApprovalHistory(newId, stageApprovalId, criticalStatus);
  }
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getJobCards();
    getLotdata();
    getDepartmentData();
    getWarehouses();
  }, []);

  const getWarehouses = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetWarehouseMasterList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setWarehouses(obj.data);
          }
        })
    );
  };

  const getDepartmentData = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetDepartment").then(
      (results) =>
        results.json().then((obj) => {
          // dept data is same for everyone
          if (results.status === 200 || results.status === 201) {
            const deptData = obj.data.filter(
              (dept) => dept.FLD_IsIncludeJobCard == "True"
            );
            deptData.push(
              {
                FLD_Department_Id: 9,
                Fld_Sequence: 0,
                FLD_Department_Name: "List All",
              },
              {
                FLD_Department_Id: 10,
                Fld_Sequence: 8,
                FLD_Department_Name: "Completed",
              }
            );
            setDeptDropdown(deptData);
          }
        })
    );
  };

  const AddWarehouseItemStock = (id) => {
    PostApiCall.postRequest(
      {
        jobcardid: id,
        warehoueid: warehouseId,
        stocktype: "Item",
      },
      "AddWarehouseItemStock"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          getLotdata();
        }
      })
    );
  };

  const getJobCards = () => {
    try {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          wherecaluse: "",
        },
        "GetActiveJobCards"
      ).then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setJobCartData(obj.data);
            setFilteredCardData(
              login[0]?.FLD_Name != "Admin" && login[0]?.FLD_Name != "Gas Filling"
                ? obj.data
                  .sort((a, b) => b.FLD_Job_Card_Id - a.FLD_Job_Card_Id)
                  .filter(
                    (card) => card?.active_stage == login[0]?.FLD_User_Name
                  )
                : obj.data // show all cards to admin
            );
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
      Notiflix.Notify.failure("Something went wrong");
      Notiflix.Loading.remove();
    }
  };

  const UpdateJobCardApprovalHistory = (
    jobCardId,
    cardApprovalId,
    criticalStatus
  ) => {
    PostApiCall.postRequest(
      {
        jobcardid: jobCardId,
        jobcardapprovalid: cardApprovalId,
        status: "Accepted",
        criticalStatus: criticalStatus,
      },
      "UpdateJobCardApprovalHistory"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          getJobCards(); // get updated card status
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };

  const getLotdata = () => {
    PostApiCall.postRequest({ deptid: login[0].FLD_Dept }, "GetCardLot").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            if (obj.data.length > 0) {
              setLotData(obj.data);
              setViewLot(true);
            } else {
              setLotData(obj.data);
            }
          }
        })
    );
  };

  // move card to the lot data
  const createJobCardSlot = () => {
    const sameItemId = lotCardIds.every((val, i, arr) => val === arr[0]);
    if (sameItemId) {
      if (cardIds.length || cardIds.length > 0) {
        PostApiCall.postRequest(
          {
            jobcardid: cardIds,
          },
          "CreateJobCardSlot"
        ).then((results) =>
          results.json().then((obj) => {
            Notiflix.Loading.remove();
            if (results.status === 200 || results.status === 201) {
              setCardIds([]);
              getLotdata(); // get lots and open the drawer
              setViewLot(true);
            } else {
              Notiflix.Notify.failure("Something went wrong");
            }
          })
        );
      } else {
        Notiflix.Notify.failure("Select at least one jobcard to create a lot", {
          position: "center-bottom",
        });
      }
    } else {
      Notiflix.Notify.failure("Cannot create lot for this combination", {
        position: "center-bottom",
      });
    }
  };

  // function to update the count at every stage
  const UpdateJobCardStage = (jobCardId, deptId, from) => {
    confirmAlert({
      title: "Confirm to Save",
      message: "Are you sure you want to save these details?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            Notiflix.Loading.dots("");
            PostApiCall.postRequest(
              {
                jobcardid: jobCardId,
                actionby: login[0].FLD_Dept,
                department: deptId,
                deadstock: deadstock,
                repairable: repairable,
                action: "closed",
                comments: "",
              },
              "UpdateJobCardStage"
            ).then((results) =>
              results.json().then((obj) => {
                Notiflix.Loading.remove();
                if (results.status === 200 || results.status === 201) {
                  Notiflix.Notify.success("Job card updated successfully!", {
                    position: "center-bottom",
                  });
                  setDeadStock(null);
                  setRepairable(null);
                  getJobCards(); // get updated card status
                  if (from === "lot") {
                    getLotdata();
                  }
                } else {
                  Notiflix.Notify.failure("Something went wrong!");
                }
              })
            );
          },
        },
        {
          label: "No",
        },
      ],
    });

  };


  const handleOk = () => {
    if (warehouseId != null) {
      setWarehouseDropdownModal(false);
      AddWarehouseItemStock(lotIds);
    } else {
      Notiflix.Notify.failure("Please select a warehouse to diaptch items");
    }
  };

  const handleCancel = () => {
    setWarehouseDropdownModal(false);
  };

  const calculateCountdownTime = (actionTime) => {
    if (!actionTime) {
      return 0; // Return 0 if actionTime is null, undefined, or invalid
    }
    try {
      const actionDate = new Date(actionTime);
      if (isNaN(actionDate.getTime())) {
        return 0; // Return 0 if the date is invalid
      }
      const endTime = new Date(actionDate.getTime() + 30 * 60 * 1000); // Add 6 hours
      const currentTime = Date.now(); // Current time
      const remainingTime = endTime.getTime() - currentTime; // Remaining time
      return remainingTime > 0 ? remainingTime : 0; // Prevent negative time
    } catch (error) {
      console.error("Error calculating countdown time:", error);
      return 0; // Return 0 in case of an error
    }
  };
  const handleChange = (value) => {
    if (value != "List All") {
      setFilteredCardData(
        jobCardData.filter((card) => card?.active_stage == value)
      );
    } else {
      setFilteredCardData(jobCardData);
    }
  };

  // function to update job stage details
  const updateJobStageDetails = (jobCardId, jobcardapprovalid, deptId) => {
    if (updDeadStk == "" || updDeadStk == null || updDeadStk < 0) {
      return Notiflix.Notify.failure("Something went wrong");
    }
    if (updRepairable == "" || updRepairable == null || updRepairable < 0) {
      return Notiflix.Notify.failure("Something went wrong");
    }
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        jobcardid: jobCardId,
        updatedby: login[0].FLD_User_Id,
        deptid: deptId,
        deadstock: updDeadStk,
        repairable: updRepairable,
        stageid: jobcardapprovalid,
      },
      "UpdateJobCardApprovalData"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Job card updated successfully!", {
            position: "center-bottom",
          });
          setIsStageEdit(false);
          getJobCards();
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  }
  // filter job cards between specific dates
  const filterByDate = () => {
    if (fromDate == null || toDate == null) {
      return Notiflix.Notify.failure("Please fill in both 'From' and 'To' dates.")
    }
    const filteredArray = jobCardData.filter(item => {
      const itemDate = moment(item.FLD_Current_Action_Time).format("YYYY-MM-DD");
      return itemDate >= fromDate && itemDate <= toDate;
    });
    setFilteredCardData(filteredArray)

  }

  return (
    <>
      <div className="margin-left blank-section job-card-accordion">
        <div className="container-fluid pt-4 pb-2">
          <div className="row pt-4 mt-4 mb-4 px-0">
            {/* Department dropdown */}
            <div className="d-flex justify-content-end gap-3">
              <div className="mb-2 d-flex justify-content-end gap-3">
                <RangePicker
                  className="h-75"
                  onChange={(date, dateString) => {
                    setFromDate(dateString[0]);
                    setToDate(dateString[1]);
                  }} />
                <Button onClick={filterByDate} className="default-btn h-75 border-0 py-2 d-flex align-items-center">Search</Button>
                <Button onClick={() => {
                  setFromDate(null)
                  setToDate(null)
                  setFilteredCardData(jobCardData)
                }} className="default-btn border-0 d-flex h-75 align-items-center"><IoMdRefresh style={{ fontSize: "1.5rem" }} /></Button>
              </div>
              <div className="mb-2 d-flex justify-content-end">
                <Select
                  defaultValue={
                    login[0].FLD_User_Name != "Admin"
                      ? login[0].FLD_User_Name
                      : "List All"
                  }
                  style={{ width: 180 }}
                  onChange={handleChange}
                >
                  {deptDropdown
                    .sort((a, b) => a.Fld_Sequence - b.Fld_Sequence)
                    .map((dept) => (
                      <Select.Option
                        key={dept.FLD_Department_Id}
                        value={dept.FLD_Department_Name}
                      >
                        {dept.FLD_Department_Name}
                      </Select.Option>
                    ))}
                </Select>
              </div>
            </div>

            {/* show projections only in tray shifting stage */}
            {(login[0].FLD_User_Name === "Tray Shifting" ||
              login[0].FLD_User_Name === "Packaging") && <Projections />}

            <div className="col-12 mb-4 px-0 px-lg-2">
              <Collapse accordion>
                {filteredCardData.map((job, jobIndex) => {
                  // Get stages data from every jobcard
                  const stages = JSON.parse(job.jobcard_stages)?.stages || [];
                  const currentStageSeq = stages.filter((itemdet) => itemdet.fld_pending_with == job.active_stage)
                  const activeStageSequence = currentStageSeq[0]?.fld_sequence;
                  const { active_stage, FLD_Current_Action_Time } = job;
                  let countdownTime = 0;
                  // calculate countdown time only for FT1 & FT2
                  if (
                    active_stage === "Flame Testing 1" ||
                    active_stage === "Flame Testing 2"
                  ) {
                    countdownTime = calculateCountdownTime(
                      FLD_Current_Action_Time
                    );
                  }
                  const criticalStage = stages.find(
                    (stage) =>
                      stage.fld_pending_with === active_stage &&
                      stage.FLD_IsCritical === "Yes"
                  );
                  if (
                    criticalStage &&
                    criticalStage.FLD_IsCritical === "Yes" &&
                    !isCritical
                  ) {
                    setIsCritical(true);
                  }
                  return (
                    <Panel
                      key={jobIndex}
                      header={
                        <>
                          <div className="d-lg-flex justify-content-between px-3 py-2">
                            <p className="card-number">
                              <div className="d-flex align-items-center">
                                <img
                                  src={job?.item_image}
                                  className="img-fluid"
                                />
                                {job.FLD_Job_Card_Number}
                              </div>
                              <span
                                style={{
                                  display: stages.some(
                                    (stage) =>
                                      stage.fld_pending_with === active_stage &&
                                      stage.FLD_IsCritical === "Yes"
                                  )
                                    ? "flex"
                                    : "none",
                                }}
                                className="align-items-center gap-2"
                              >
                                <MdWarning className="text-warning" />
                                <p>
                                  This job card was accepted at {active_stage}{" "}
                                  before 6 hours
                                </p>
                              </span>
                            </p>
                          </div>
                          <div className="mt-2 border-top pt-2 px-3 inner-accordion-header">
                            <p>
                              <span>Total Quantity : </span>
                              {job.FLD_Item_Qty}
                            </p>
                            <p>
                              <span>Current Quantity : </span>
                              {job.FLD_Current_Quantity}
                            </p>
                            <p>
                              <span>Stage : </span>
                              {job.active_stage || "Flame Test 1"}
                            </p>

                            <Countdown
                              style={{
                                display:
                                  countdownTime > 0 &&
                                    (active_stage === "Flame Testing 1" ||
                                      active_stage === "Flame Testing 2") &&
                                    isCritical
                                    ? "flex"
                                    : "none",
                              }}
                              title="This Batch is currently on hold for the remaining duration"
                              value={Date.now() + countdownTime} // Set the deadline dynamically
                              className="countdown w-100 justify-content-lg-end"
                              onFinish={() =>
                                notification.success({
                                  message: "Countdown Completed!",
                                })
                              }
                              format="HH:mm:ss"
                            />
                          </div>
                          {/*  change stage status with checkbox */}
                          {login[0]?.FLD_Dept == 6 ? (
                            <div className="tray-checkbox">
                              {(() => {
                                const approvalData = stages.filter(
                                  (pendingStage) =>
                                    pendingStage.fld_pending_with ==
                                    active_stage
                                );
                                const stageApprovalId =
                                  approvalData[0]?.FLD_Job_Card_Approval_Id;

                                let criticalStatus;
                                if (countdownTime > 0) {
                                  criticalStatus = "Yes";
                                } else {
                                  criticalStatus = "No";
                                }
                                return (
                                  <>
                                    {lotData.length > 0 &&
                                      lotData.some(
                                        (lot) =>
                                          lot?.FLD_Job_Card_Id ==
                                          job?.FLD_Job_Card_Id
                                      ) ? (
                                      <p>Already in lot</p>
                                    ) : (
                                      <Checkbox
                                        onChange={() =>
                                          appendCardId(
                                            job.FLD_Job_Card_Id,
                                            stageApprovalId,
                                            criticalStage,
                                            job.FLD_Item_Id
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    )}
                                  </>
                                );
                              })()}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      }
                    >
                      <Collapse className="inner-collapse" accordion>
                        {stages.map((stage, stageIndex) => {
                          var editabeStage;
                          if (stage.fld_sequence === activeStageSequence - 1) {
                            editabeStage = true;
                          }
                          return stage.fld_action === "closed" ? (
                            <Panel
                              key={stageIndex}
                              header={
                                <div className="px-3 py-2 mt-1">
                                  <p>{stage.fld_pending_with}</p>
                                </div>
                              }
                            >
                              <div className="stage-card">
                                <div className="d-flex justify-content-between ">
                                  <span className="top-text">
                                    The details of the batch of lighters processed
                                    at this stage are as follows:
                                  </span>
                                  {isStageEdit === false && editabeStage === true && login[0].FLD_Access_Dept.includes(
                                    stage.Fld_Department_Id
                                  ) &&
                                    <Button
                                      className="edit-stage-btn border-0 py-2 d-flex align-items-center" onClick={() => {
                                        setIsStageEdit(true)
                                        setUpdQtyIn(stage.FLD_Item_Qty_In)
                                        setUpdDeadStk(stage.FLD_Dead_Stock_Qty_In)
                                        setUpdRepairable(stage.FLD_Repairable_Qty)
                                        setUpdQtyOut(stage.FLD_Item_Qty_Out)
                                      }}><MdEdit />
                                    </Button>
                                  }
                                </div>
                                <div className="stage-content">
                                  <div className="box">
                                    <span>Quantity In :</span>
                                    {isStageEdit === true ?
                                      <Input
                                        disabled={true}
                                        allowClear
                                        className="input-box w-50"
                                        value={updQtyIn}
                                        onChange={(e) =>
                                          setUpdQtyIn(e.target.value)
                                        }
                                      />
                                      :
                                      <p>{stage.FLD_Item_Qty_In}</p>
                                    }
                                  </div>
                                  <div className="box">
                                    <span>Dead Stock :</span>
                                    {isStageEdit === true
                                      ?
                                      <Input
                                        disabled={
                                          !login[0].FLD_Access_Dept.includes(
                                            stage.Fld_Department_Id
                                          )
                                        }
                                        allowClear
                                        className="input-box w-50"
                                        value={updDeadStk}
                                        onChange={(e) =>
                                          setUpdDeadStk(e.target.value)
                                        }
                                      />
                                      :
                                      <p>{stage.FLD_Dead_Stock_Qty_In}</p>
                                    }
                                  </div>
                                  <div className="box border-0">
                                    <span>Repairable :</span>
                                    {isStageEdit === true ?
                                      <Input
                                        disabled={
                                          !login[0].FLD_Access_Dept.includes(
                                            stage.Fld_Department_Id
                                          )
                                        }
                                        allowClear
                                        className="input-box w-50"
                                        value={updRepairable}
                                        onChange={(e) =>
                                          setUpdRepairable(e.target.value)
                                        }
                                      />
                                      :
                                      <p>{stage.FLD_Repairable_Qty}</p>
                                    }
                                  </div>
                                  <div className="box border-0">
                                    <span>Quantity Out :</span>
                                    {isStageEdit === true ?
                                      <Input
                                        allowClear
                                        className="input-box w-50"
                                        disabled={true}
                                        value={updQtyOut}
                                        onChange={(e) =>
                                          setUpdQtyOut(e.target.value)
                                        }
                                      />
                                      :
                                      <p>{stage.FLD_Item_Qty_Out}</p>
                                    }
                                  </div>
                                </div>
                                {isStageEdit === true && editabeStage === true && <div className="d-flex justify-content-end gap-2">
                                  <Button
                                    className="default-btn border-0 mt-3" onClick={() =>
                                      updateJobStageDetails(
                                        job.FLD_Job_Card_Id,
                                        stage.FLD_Job_Card_Approval_Id,
                                        stage.Fld_Department_Id
                                      )}> Update
                                  </Button>
                                  <Button
                                    className="default-btn border-0 mt-3" onClick={() => setIsStageEdit(false)}>Cancel
                                  </Button>
                                </div>
                                }
                              </div>
                            </Panel>
                          ) : (
                            <Panel
                              key={stageIndex}
                              header={
                                <div className="px-3 py-2 mt-1 d-flex">
                                  <p>{stage.fld_pending_with}</p>
                                  <span
                                    className="ms-2"
                                    style={{
                                      display:
                                        stage.FLD_IsCritical === "Yes"
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <MdWarning className="text-warning" />
                                  </span>
                                </div>
                              }
                            >
                              <div className="stage-card">
                                {(stage?.fld_status === "Open" ||
                                  stage.fld_status === "") && (
                                    <div className="accepte-box">
                                      {stage.FLD_Hold_Time !== "00:00:00" &&
                                        countdownTime > 0 && (
                                          <>
                                            <p className="fs-6">
                                              <IoIosWarning className="text-warning" />{" "}
                                              CAUTION: TIMING CRITICAL{" "}
                                              <IoIosWarning className="text-warning" />
                                            </p>
                                            <span>
                                              This batch of lighters must not move
                                              to the next stage until the
                                              remaining time on the 6-hour
                                              timestamp expires.
                                            </span>
                                          </>
                                        )}

                                      {stage.fld_status === "" && (
                                        <>
                                          <span>
                                            Access is restricted until all the
                                            above stages are completed and
                                            accepted.
                                          </span>
                                        </>
                                      )}
                                      {stage.fld_status === "Open" &&
                                        login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        ) && (
                                          <>
                                            {countdownTime <= 0 && (
                                              <span>
                                                Are you sure you want to accept
                                                the batch into the{" "}
                                                {stage.fld_pending_with} stage
                                                now?
                                              </span>
                                            )}
                                            <span
                                              className="default-btn"
                                              onClick={() => {
                                                let criticalStatus;
                                                if (countdownTime > 0) {
                                                  criticalStatus = "Yes";
                                                } else {
                                                  criticalStatus = "No";
                                                }
                                                UpdateJobCardApprovalHistory(
                                                  job.FLD_Job_Card_Id,
                                                  stage.FLD_Job_Card_Approval_Id,
                                                  criticalStatus
                                                );
                                              }}
                                            >
                                              Accept
                                            </span>
                                          </>
                                        )}
                                    </div>
                                  )}
                                <span className="top-text">
                                  Enter the details of batch of lighters
                                  processed at {stage.fld_pending_with},
                                  including deadstock & repairable quantity.
                                </span>
                                <div className="stage-input">
                                  <div className="box">
                                    <span>Quantity In :</span>
                                    <p>{stage.FLD_Item_Qty_In}</p>
                                  </div>
                                  <div className="box">
                                    <span>Dead Stock</span>
                                    <Input
                                      disabled={
                                        !login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                      }
                                      allowClear
                                      className="input-box"
                                      value={deadstock}
                                      onChange={(e) =>
                                        setDeadStock(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="box">
                                    <span>Repairable</span>
                                    <Input
                                      disabled={
                                        !login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                      }
                                      allowClear
                                      className="input-box"
                                      value={repairable}
                                      onChange={(e) =>
                                        setRepairable(e.target.value)
                                      }
                                    />
                                  </div>
                                  {/* <div className="box border-0">
                                    <span>Quantity Out</span>
                                    <Input
                                      disabled={
                                        !login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                      }
                                      allowClear
                                      className="input-box"
                                      value={qtyOut}
                                      onChange={(e) =>
                                        setQtyOut(e.target.value)
                                      }
                                    />
                                  </div> */}
                                  <div
                                    className="mt-3 text-end"
                                    style={{
                                      display:
                                        login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <span
                                      className="default-btn"
                                      onClick={() =>
                                        UpdateJobCardStage(
                                          job.FLD_Job_Card_Id,
                                          stage.Fld_Department_Id,
                                          "job"
                                        )
                                      }
                                    >
                                      Save
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          );
                        })}
                      </Collapse>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>

            {!filteredCardData.length && (
              <p className="text-center">No job card available at this stage</p>
            )}
            {jobCardData.length && login[0]?.FLD_Dept == 6 && (
              <div className="tray-shifting-floting-btn">
                <span
                  className="default-btn fs-5 w-100 d-block"
                  onClick={() => {
                    viewLot ? showDrawer() : createJobCardSlot();
                  }}
                >
                  {viewLot ? "View Card Lot" : "Create Job Card Lot"}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <Drawer
        title="Create a Lot"
        onClose={onClose}
        open={open}
        placement="bottom"
        className="tray-shifting-drawer job-card-page supervisor-filter-drawer tray-shifting-page"
      >
        <div className="job-card-box h-auto mb-3">
          <div className="box">
            <h4 className="text-dark pb-1">Projections</h4>
          </div>
          <div className="box content justify-content-between">
            <p className="w-50">
              <span>Boxes : </span>
              1000
            </p>
            <p className="w-50">
              <span>Cartons : </span>
              100
            </p>
          </div>
        </div>
        <div className="container-fluid pb-2">
          <div className="row mb-4 px-0">
            {lotData.map((lot, lotIndex) => {
              const stages = JSON.parse(lot.jobcard_stages)?.stages || [];
              let jobCardIds = "";
              for (let cardId in lotData) {
                if (jobCardIds) {
                  jobCardIds += ",";
                }
                jobCardIds += lotData[cardId].FLD_Job_Card_Id;
              }
              setLotIds(jobCardIds);
              return (
                <div className="col-lg-4 px-0 px-lg-2">
                  <Collapse accordion>
                    <Panel
                      header={
                        <div className="job-card-box p-0 border-0">
                          <div className="box border-0 mb-2">
                            <div className="d-flex align-items-center">
                              <img src={lot.item_image} className="img-fluid" />
                              <p className="card-number">
                                {lot.FLD_Job_Card_Number}
                              </p>
                            </div>
                          </div>
                          <div className="box content">
                            <p>
                              <span>Total Quantity : </span>
                              {lot.FLD_Item_Qty}
                            </p>
                          </div>
                          <div className="box content">
                            <p>
                              <span>Current Quantity : </span>
                              {lot.FLD_Current_Quantity}
                            </p>
                          </div>
                        </div>
                      }
                      key={lotIndex}
                    >
                      {stages?.map((stage) => {
                        return stage.fld_status == "Closed" ? (
                          <>
                            <div className="job-card-box p-0 border-0">
                              <div className="stage-content">
                                <div className="box">
                                  <span>Quantity In :</span>
                                  <p>{stage.FLD_Item_Qty_In}</p>
                                </div>
                                <div className="box">
                                  <span>Dead Stock :</span>
                                  <p>{stage.FLD_Dead_Stock_Qty_In}</p>
                                </div>
                                <div className="box border-0">
                                  <span>Repairable :</span>
                                  <p>{stage.FLD_Repairable_Qty}</p>
                                </div>
                                <div className="box border-0">
                                  <span>Quantity Out :</span>
                                  <p>{stage.FLD_Item_Qty_Out}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="stage-input">
                              <div className="box">
                                <span>Quantity In :</span>
                                <p>{stage.FLD_Item_Qty_In}</p>
                              </div>
                              <div className="box">
                                <span>Dead Stock</span>
                                <Input
                                  allowCleardisabled={
                                    !login[0].FLD_Access_Dept.includes(
                                      stage.Fld_Department_Id
                                    )
                                  }
                                  allowClear
                                  className="input-box"
                                  value={deadstock}
                                  onChange={(e) => setDeadStock(e.target.value)}
                                />
                              </div>
                              <div className="box">
                                <span>Repairable</span>
                                <Input
                                  allowClear
                                  disabled={
                                    !login[0].FLD_Access_Dept.includes(
                                      stage.Fld_Department_Id
                                    )
                                  }
                                  className="input-box"
                                  value={repairable}
                                  onChange={(e) =>
                                    setRepairable(e.target.value)
                                  }
                                />
                              </div>

                              <div className="mt-3 text-end">
                                <span
                                  className="default-btn"
                                  onClick={() =>
                                    UpdateJobCardStage(
                                      lot.FLD_Job_Card_Id,
                                      stage.Fld_Department_Id,
                                      "lot"
                                    )
                                  }
                                >
                                  Save
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Panel>
                  </Collapse>
                </div>
              );
            })}
          </div>
        </div>
        <div className="fixed-bottom p-2 bg-white w-auto">
          <span
            className="default-btn fs-5 w-100 d-block text-center w-auto"
            onClick={() => setWarehouseDropdownModal(true)}
          // onClick={() => console.log(lotIds)}
          >
            Select Warehouse & Continue
          </span>
        </div>
        <Modal
          title="Select Warehouse"
          open={openWrehouseDropdownModal}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Dispatch Items"
          zIndex={1000}
        >
          <Select
            size="middle"
            className="input-box w-100 my-3"
            // placeholder="Choose Warehouse"
            onChange={(value) => setWarehouseId(value)}
          >
            {warehouses.map((warehouse) => (
              <Select.Option
                key={warehouse.FLD_Warehouse_Id}
                value={warehouse.FLD_Warehouse_Id}
              >
                {warehouse.FLD_Warehouse_Name}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      </Drawer>
    </>
  );
}
