import React, { useEffect, useState } from "react";
import { BiSolidReceipt } from "react-icons/bi";
import {
  BsBoxFill,
  BsFillHouseDoorFill,
  BsFillPersonVcardFill,
  BsHouseGearFill,
  BsPersonFill,
  BsPersonFillGear,
} from "react-icons/bs";
import { FaRectangleList } from "react-icons/fa6";
import { HiShoppingCart } from "react-icons/hi";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./SideNavbar.css";
import { IoNotifications } from "react-icons/io5";

export default function ManagerSideNvbar({ handleCollapse }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const targetPath =
    windowWidth > 568 ? "/spares-request-form" : "/spare-request-list";
  return (
    <Menu>
      <Link to={"/dashboard"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <BsFillHouseDoorFill className="sidebar-icon" /> Dashboard
        </MenuItem>
      </Link>
      <SubMenu
        label="Production Management"
        className="sidebar-menuitem"
        icon={<BsHouseGearFill className="sidebar-icon" />}
      >
        <MenuItem>
          {" "}
          <Link to={"/create-job-card"} onClick={() => handleCollapse()}>
            Job Card
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/active-job-card-list"} onClick={() => handleCollapse()}>
            Active Job Cards
          </Link>
        </MenuItem>
      </SubMenu>
      <SubMenu
        label="Stock Management"
        className="sidebar-menuitem"
        icon={<FaRectangleList className="sidebar-icon" />}
      >
        <MenuItem>
          {" "}
          <Link to={"/item-stock"} onClick={() => handleCollapse()}>
            Item Stock Statement
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={targetPath} onClick={() => handleCollapse()}>
            Spare Request
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/item-spares-stock"} onClick={() => handleCollapse()}>
            Stock Update
          </Link>
        </MenuItem>
      </SubMenu>
      <Link to={"/customer-master"}>
        <MenuItem className="sidebar-menuitem" onClick={() => handleCollapse()}>
          <BsFillPersonVcardFill className="sidebar-icon" /> Customer Management
        </MenuItem>
      </Link>

      <SubMenu
        label="Item Management"
        className="sidebar-menuitem"
        icon={<HiShoppingCart className="sidebar-icon" />}
      >
        <MenuItem>
          {" "}
          <Link to={"/item-spares-master"} onClick={() => handleCollapse()}>
            Item Spares Master
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/item-master"} onClick={() => handleCollapse()}>
            Item Master
          </Link>
        </MenuItem>
      </SubMenu>

      <SubMenu
        label="Master Management"
        className="sidebar-menuitem"
        icon={<BsPersonFill className="sidebar-icon" />}
      >
        <MenuItem>
          {" "}
          <Link to={"/company-master"} onClick={() => handleCollapse()}>
            Company Master
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/brand-master"} onClick={() => handleCollapse()}>
            Brand Master
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/store-master"} onClick={() => handleCollapse()}>
            Store Master
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/warehouse-master"} onClick={() => handleCollapse()}>
            Warehouse Master
          </Link>
        </MenuItem>
      </SubMenu>

      <Link to={"/staff-master"}>
        <MenuItem className="sidebar-menuitem" onClick={() => handleCollapse()}>
          <BsPersonFillGear className="sidebar-icon" /> Staff Management
        </MenuItem>
      </Link>

      <Link to={"/reports"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <BiSolidReceipt className="sidebar-icon" /> Reports
        </MenuItem>
      </Link>
      <Link to={"/order-master"}>
        <MenuItem className="sidebar-menuitem" onClick={() => handleCollapse()}>
          <BsBoxFill className="sidebar-icon" /> Dispatch Management
        </MenuItem>
      </Link>

      <Link to={"/notification-master"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <IoNotifications className="sidebar-icon" /> Notification Management
        </MenuItem>
      </Link>
    </Menu>
  );
}
