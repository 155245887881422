import React, { useState, useEffect } from "react";
import { Input, message, Switch, Upload } from "antd";
import Search from "antd/es/input/Search";
import { Button } from "react-bootstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

// For integrate brands form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";

// For get uploaded image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function BrandMaster({ brandList, getBrands }) {
  const [editingBrandId, setEditingBrandId] = useState(null);
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState("");

  // For image
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.floor(100000 + Math.random() * 900000))
  );

  // Initilize UseForm
  const {
    formState: { errors },
  } = useForm();

  // For edit forms
  const handleEditBrand = (BrandId, action = "Edit") => {
    const brandUpdate = brandList.find(
      (brand) => brand.FLD_Brand_Id === BrandId
    );
    setFormData({
      brandname: brandUpdate.FLD_Brand_Name,
      brandlogo: brandUpdate.FLD_Brand_Logo,
      status: brandUpdate.FLD_Enabled_Flag === "Active",
    });
    if (action === "Delete") {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure you want to delete this brand?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              Notiflix.Loading.dots("");
              try {
                handleUpdateBrand(BrandId, brandUpdate, "Delete");
              } catch (error) {
                Notiflix.Notify.failure(error);
              }
            },
          },
          {
            label: "No",
            onClick: async () => {
              setEditingBrandId(null);
            },
          },
        ],
        closeOnClickOutside: false,
      });
    }
    setEditingBrandId(BrandId);
  };

  // same function with different payloads to delete and update
  const handleUpdateBrand = (BrandId, brandData = "", action = "Delete") => {
    Notiflix.Loading.dots("");
    const updatBrandPayload =
      action === "Delete"
        ? {
            brandUniqId: BrandId,
            brandlogo: imageUrl === "" ? brandData.FLD_Brand_Logo : imageUrl,
            brandname: brandData.FLD_Brand_Name,
            status: "Deleted",
          }
        : {
            brandUniqId: BrandId,
            brandlogo: imageUrl === "" ? formData.brandlogo : imageUrl,
            brandname: formData.brandname,
            status: formData.status ? "Active" : "Inactive",
          };
    PostApiCall.postRequest(updatBrandPayload, "AddBrand").then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Brand updated successfully.");
          setEditingBrandId(null);
          getBrands(); // reload the list
        } else {
          Notiflix.Notify.failure("Brand update failed!");
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // For edit and update status
  const handleToggleChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      status: checked,
    }));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const filteredBrandList = brandList.filter(
    (brand) =>
      brand.FLD_Brand_Name &&
      brand.FLD_Brand_Name.toLowerCase().includes(searchValue.toLowerCase())
  );

  // Handel image on server
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Brand Logo
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "brandImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setImageUrl("");
  }, [editingBrandId]);
  return (
    <div className="row pt-0 mt-0 mb-4 px-0">
      <div className="col-12 px-0">
        <div className="default-card default-card-border mt-2">
          <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
            <h4>
              List of Brands (
              {
                filteredBrandList.filter(
                  (brand) => brand.FLD_Enabled_Flag !== "Deleted"
                ).length
              }{" "}
              Nos.)
            </h4>
            <div className="search-box w-50">
              <Search
                placeholder="Search Brand"
                allowClear
                className="w-100"
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
          </div>
          <div className="row">
            {filteredBrandList?.length > 0 ? (
              filteredBrandList
                .filter((brand) => brand.FLD_Enabled_Flag !== "Deleted")
                ?.map((branddata) => (
                  <div className=" col-lg-3 mb-4">
                    <div className="spare-card h-100">
                      <div className="row">
                        <div
                          className="col-12 pb-3"
                          key={branddata.FLD_Brand_Id}
                        >
                          <Upload
                            disabled={editingBrandId !== branddata.FLD_Brand_Id}
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader w-100"
                            showUploadList={false}
                            customRequest={onaction}
                            beforeUpload={beforeUpload}
                          >
                            {editingBrandId !== branddata.FLD_Brand_Id ? (
                              <img
                                src={branddata.FLD_Brand_Logo}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                }}
                              />
                            ) : branddata.FLD_Brand_Logo !== "" &&
                              imageUrl === "" ? (
                              <>
                                {
                                  <img
                                    src={branddata.FLD_Brand_Logo}
                                    alt="avatar"
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                }
                              </>
                            ) : (
                              <>
                                {imageUrl === "" ? (
                                  uploadButton
                                ) : (
                                  <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Upload>
                        </div>
                        <div className="col-12 pb-3">
                          <Input
                            size="large"
                            placeholder="Brand Name"
                            allowClear
                            className="input-box"
                            name="brandname"
                            defaultValue={branddata.FLD_Brand_Name}
                            disabled={editingBrandId !== branddata.FLD_Brand_Id}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="col-12">
                          <div className="btn-border mt-2 pt-2">
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Inactive</p>
                              <Switch
                                checked={
                                  editingBrandId === branddata.FLD_Brand_Id
                                    ? formData.status
                                    : branddata.FLD_Enabled_Flag === "Active"
                                }
                                disabled={
                                  editingBrandId !== branddata.FLD_Brand_Id
                                }
                                onChange={handleToggleChange}
                              />
                              <p className="mb-0">Active</p>
                            </div>
                            <p className="mb-0 mt-2">
                              Last Edited -
                              {branddata.FLD_Updated_on && (
                                <>
                                  {moment(branddata.FLD_Updated_on).format(
                                    "DD-MM-YYYY"
                                  )}
                                </>
                              )}
                            </p>

                            <div className="d-flex flex-column gap-3">
                              <Button
                                className="default-btn border-0 mt-3"
                                onClick={() =>
                                  handleEditBrand(
                                    branddata.FLD_Brand_Id,
                                    "Delete"
                                  )
                                }
                              >
                                Delete Brand
                              </Button>
                              <Button
                                className="default-btn border-0"
                                onClick={() =>
                                  editingBrandId === branddata.FLD_Brand_Id
                                    ? handleUpdateBrand(
                                        branddata.FLD_Brand_Id,
                                        "",
                                        "Edit"
                                      )
                                    : handleEditBrand(branddata.FLD_Brand_Id)
                                }
                              >
                                {editingBrandId === branddata.FLD_Brand_Id
                                  ? "Update Brand"
                                  : "Edit Brand"}
                              </Button>
                              {editingBrandId === branddata.FLD_Brand_Id && (
                                <Button
                                  className="ml-2 default-btn border-0 mt-3"
                                  onClick={() => setEditingBrandId(null)}
                                >
                                  Cancel
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
