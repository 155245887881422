import React, { useEffect, useState } from "react";
import { BsPersonLinesFill } from "react-icons/bs";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./TopHeader.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import PostApiCall from "../../PostApi";
import NewJobCard from "../../assets/img/topheader-icons/new-job-card.png";
import Completed from "../../assets/img/topheader-icons/completed.png";
import TotalRepairable from "../../assets/img/topheader-icons/total-repairable.png";
import DeadStock from "../../assets/img/topheader-icons/dead-stock.png";
import ItemsonAssembly from "../../assets/img/topheader-icons/items-on-assembly.png";
import AvailableCylinders from "../../assets/img/topheader-icons/available-cylinders.png";
// import required modules
export const TopHeader = () => {
  const [completedJobCards, setCompletedJobCards] = useState([]);
  const [totalRejections, setTotalRejections] = useState([]);
  const [deadStock, setDeadStock] = useState([]);
  const [newJobCards, setNewJobCards] = useState([]);
  const [itemsOnAssembly, setItemsOnAssembly] = useState([]);
  const [availableCylinders, setAvailableCylinders] = useState([]);

  useEffect(() => {
    PostApiCall.postRequest({}, "GetAdminDashChips")
      .then((result) => result.json())
      .then((obj) => {
        setCompletedJobCards(obj.data[0].CompletedJobCards);
        setTotalRejections(obj.data[0].RepaiableRejections);
        setDeadStock(obj.data[0].DeadStock);
        setNewJobCards(obj.data[0].NewJobCards);
        setItemsOnAssembly(obj.data[0].DispatchFromAssembly);
        setAvailableCylinders(obj.data[0].OrdersThisMonth);
      });
  }, []);

  return (
    <>
      <div className="margin-left">
        <div className="container-fluid pt-5">
          <div className="row pt-4 mb-lg-4">
            <div className="col-12 mt-3 mt-lg-4 px-0">
              <Swiper
                slidesPerView={1.5}
                spaceBetween={15}
                loop={false}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 25,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper top-header-swiper">
                <div className="top-header-box">
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img src={NewJobCard} className="img-fluid" alt="New Job Cards" />
                      </div>
                      <div>
                        <span>New Job Cards</span>
                        <p>{newJobCards}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img src={Completed} className="img-fluid" alt="Completed" />
                      </div>
                      <div>
                        <span>Completed</span>
                        <p>{completedJobCards}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={TotalRepairable}
                          className="img-fluid"
                          alt="Total Repairable"
                        />
                      </div>
                      <div>
                        <span>Total Repairable</span>
                        <p>{totalRejections}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img src={DeadStock} className="img-fluid" alt="Dead Stock" />
                      </div>
                      <div>
                        <span>Dead Stock</span>
                        <p>{deadStock}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={ItemsonAssembly}
                          className="img-fluid"
                          alt="Items on Assembly"
                        />
                      </div>
                      <div>
                        <span>Items on Assembly</span>
                        <p>{itemsOnAssembly}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={AvailableCylinders}
                          className="img-fluid"
                          alt="Available Cylinders"
                        />
                      </div>
                      <div>
                        <span>Available Cylinders</span>
                        <p>{availableCylinders}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>
            {/* <div className="col-lg-3 mt-4 pe-lg-0">
              <div className="top-header client-logo">
                <img src={ClientLogo} className="img-fluid" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
