import { Select, Table } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import PostApiCall from "../../PostApi";
export default function Reports() {
    const [activeTab, setActiveTab] = useState("store");
    const [warehouseStock, setWrehouseStock] = useState([]);
    const [filterDropdown, setFilterDropdown] = useState([]);
    const [warehouseList, setWarehouseList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [filteredId, setFilteredId] = useState(null);

    const [storeStock, setStoreStock] = useState([]);
    const handleChange = (value) => {
        setFilteredId(value);
    };
    useEffect(() => {
        const getStoreList = () => {
            PostApiCall.postRequest({ whereClause: "" }, "GetStoreList").then(
                (results) =>
                    results.json().then((obj) => {
                        if (results.status === 200 || results.status === 201) {
                            setStoreList(obj.data);
                            setFilterDropdown(obj.data);
                        }
                    })
            );
        };
        const getWarehouses = () => {
            PostApiCall.postRequest(
                { whereClause: "" },
                "GetWarehouseMasterList"
            ).then((results) =>
                results.json().then((obj) => {
                    if (results.status === 200 || results.status === 201) {
                        setWarehouseList(obj.data);
                    }
                })
            );
        };
        PostApiCall.postRequest({}, "GetWarehouseItemStockList").then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    setWrehouseStock(obj.data);
                }
            })
        );
        PostApiCall.postRequest({}, "GetItemSpareStockList").then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    setStoreStock(obj.data);
                }
            })
        );
        getStoreList();
        getWarehouses();
    }, []);
    dayjs.extend(customParseFormat);

    // Same cols for store and warehouse
    const columns = [
        {
            title: "S No.",
            dataIndex: "serial",
            key: "serial",
        },
        {
            title: "Item Name",
            dataIndex: "itemName",
            key: "itemName",
        },
        {
            title: "Total Stock",
            dataIndex: "totalStock",
            key: "totalStock",
        },
    ];

    // Same row data for store and warehouse statement
    const mappedData = warehouseStock
        .filter((warehouse) => warehouse.FLD_Warehouse_Id == filteredId)
        .map((stock, index) => {
            return {
                key: index + 1,
                serial: index + 1,
                itemName: stock?.item_name,
                totalStock: stock?.FLD_Warehouse_Item_Quantity,
            };
        });

    const mappedStoreStock = storeStock
        .filter((stock) => stock.FLD_Store_Id == filteredId)
        .map((stock, index) => {
            return {
                key: index + 1,
                serial: index + 1,
                itemName: stock?.spare_name,
                totalStock: stock?.quantity,
            };
        });

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
        if (tab == "warehouse") {
            setFilterDropdown(warehouseList);
            setFilteredId(null);
        } else {
            setFilterDropdown(storeList);
            setFilteredId(null);
        }
    };

    return (
        <div className="margin-left blank-section">
            <div className="container-fluid pt-4 pb-0">
                <div className="row pt-4 mt-4 mb-0 px-0">
                    <div className="col-12 px-0">
                        <div className="default-card default-card-border job-card-list-table">
                            <div className="card-heading">
                                <h4>
                                    {" "}
                                    {activeTab == "store"
                                        ? "Store Stock Statement"
                                        : "Warehouse Stock Statement"}
                                </h4>
                            </div>
                            <div className="tab-filter">
                                <Tab.Container
                                    defaultActiveKey="store"
                                    onSelect={handleTabSelect}
                                >
                                    <div className=" mb-4 w-100 flex-wrap gap-4 d-flex justify-content-between align-items-end">
                                        <Nav
                                            variant="pills"
                                            className="flex-row m-0 w-auto ml-auto"
                                        >
                                            <Nav.Item>
                                                <Nav.Link eventKey="store">Store</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="warehouse">Warehouse</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <div>
                                            <Select
                                                size="large"
                                                value={filteredId}
                                                placeholder={
                                                    activeTab == "store"
                                                        ? "Choose Store"
                                                        : "Choose Warehouse"
                                                }
                                                onChange={handleChange}
                                            >
                                                {filterDropdown.map((warehouse) => (
                                                    <Select.Option
                                                        key={
                                                            warehouse.FLD_Store_Id
                                                                ? warehouse.FLD_Store_Id
                                                                : warehouse.FLD_Warehouse_Id
                                                        }
                                                        value={
                                                            warehouse.FLD_Store_Id
                                                                ? warehouse.FLD_Store_Id
                                                                : warehouse.FLD_Warehouse_Id
                                                        }
                                                    >
                                                        {warehouse?.FLD_Store_Name
                                                            ? warehouse?.FLD_Store_Name
                                                            : warehouse?.FLD_Warehouse_Name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            <button
                                                type="button"
                                                className="default-btn border-0 ms-lg-3"
                                            >
                                                Download CSV
                                            </button>
                                        </div>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="store">
                                            <Table
                                                bordered
                                                className="operation-expense-table-size"
                                                columns={columns}
                                                dataSource={mappedStoreStock}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="warehouse">
                                            <Table
                                                bordered
                                                className="operation-expense-table-size"
                                                columns={columns}
                                                dataSource={mappedData}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
