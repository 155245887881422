import { Input, Table, Modal, Select } from "antd";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import PostApiCall from "../../PostApi";
import { Link, useLocation } from "react-router-dom";
import NoImg from "../../assets/img/no-img.jpg";
import { FaEye } from "react-icons/fa";
import Notiflix from "notiflix";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MdDelete } from "react-icons/md";

export default function SparesRequestForm() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const location = useLocation();
  const formState = location.state;
  const [showForm, setShowForm] = useState(formState ? true : false);
  const [show, setShow] = useState(false); // get the added item drawer
  const handleClose = () => setShow(false);
  const [requestedSpare, setRequestedSpare] = useState([]);
  const [localItems, setLocalItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [reqWarehouse, setReqWarehouse] = useState(null);
  const [reqStore, setReqStore] = useState(null);
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [tempDispatchData, setTempDispatchData] = useState([]);
  const [reqId, setReqId] = useState(null);
  const [spares, setSpares] = useState([]);

  useEffect(() => {
    getStoreList();
    getWarehouses();
    getSpareRequests();
    getSpareItems();
  }, []);
  const getStoreList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetStoreList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setStoreList(obj.data);
          }
        })
    );
  };
  const getWarehouses = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetWarehouseMasterList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setWarehouseList(obj.data);
          }
        })
    );
  };
  const getSpareRequests = () => {
    Notiflix.Loading.dots();
    var tempWhere =
      login[0].FLD_User_Type !== 4 && login[0].FLD_User_Type !== 3
        ? `where FLD_Created_By = ${login[0].FLD_User_Id} and FLD_Request_From = 'store' `
        : `where FLD_Request_From = 'store' `;
    PostApiCall.postRequest(
      {
        whereClause: tempWhere,
      },
      "GetSpareRequests"
    ).then((resultdes) => {
      resultdes.json().then((obj) => {
        try {
          const transformedData = obj.data.map((item, index) => ({
            key: index + 1,
            serial: index + 1,
            orderNo: item.FLD_Req_No,
            itemCount: item.items_count,
            requestedOn: item.FLD_Spares_Stock_Request_Date,
            status: item.FLD_Enabled_Flag,
            action: (
              <div>
                <button
                  type="button"
                  onClick={() => {
                    onOpenDispatch(
                      item,
                      item.FLD_Spares_Request_Id,
                      item.FLD_Enabled_Flag
                    );
                  }}
                  className="default-btn border-0"
                >
                  View <FaEye />
                </button>
              </div>
            ),
          }));

          setRequestedSpare(transformedData);
        } catch (error) {
          console.log(error);
          Notiflix.Loading.remove();
        }
        Notiflix.Loading.remove();
      });
    });
  };

  // get items from localstorage and show inside a drawer
  const handleShow = () => {
    const existingItems =
      JSON.parse(localStorage.getItem("requestedItems")) || [];
    setLocalItems(existingItems); // Update state with items
    setShow(true);
  };

  const handleToggleView = (action) => {
    if (action == "Back") {
      setShowForm(false);
    } else if (action == "Form") {
      setShowForm(true);
    }
  };

  const handleOk = () => {
    sendSpareRequest(localItems);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getSpareItems = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetSpareItemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setSpares(obj.data);
          }
        })
    );
  };

  const sendSpareRequest = (itemdetails) => {
    const jsonData = Object.values(itemdetails);
    const items = JSON.stringify(jsonData);
    const notificationBody =
      login[0]?.FLD_User_Type == 4 || login[0]?.FLD_User_Type == 3
        ? "Stock Added Successfully"
        : "Request send succesfully";
    PostApiCall.postRequest(
      {
        orderid: null,
        userid: login[0]?.FLD_User_Id,
        itemdetails: items,
        requestfrom: login[0].FLD_User_Type == 4 ? "warehouse" : "store",
        warehouseid: reqWarehouse,
        storeid: reqStore,
      },
      "RequestSpareItems"
    ).then((results) =>
      results.json().then((obj) => {
        try {
          if (results.status === 200 || results.status === 201) {
            localStorage.removeItem("requestedItems");
            setLocalItems([]);
            setIsModalOpen(false);
            Notiflix.Notify.success(notificationBody);
          } else {
            Notiflix.Notify.failure(obj.data);
          }
        } catch (error) {
          console.log(error);
        }
      })
    );
  };

  const handlecloseDispatchMmodal = () => {
    setShowDispatchModal(false);
  };

  const onOpenDispatch = (data, requestId, status) => {
    const parsedItems = JSON.parse(data?.item_details)?.items || [];
    setReqId(requestId);
    setTempDispatchData(parsedItems);
    setShowDispatchModal(true);
  };

  const columns = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
      key: "orderNo",
    },
    {
      title: "Item Count",
      dataIndex: "itemCount",
      key: "itemCount",
    },
    {
      title: "Requested On",
      dataIndex: "requestedOn",
      key: "requestedOn",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
    },
  ];

  const handleItemToLocal = (itemid, spareimg, sparename) => {
    // Quantity input value
    const inputElement = document.getElementById(`quantity-${itemid}`);
    const quantity = inputElement ? parseInt(inputElement.value, 10) : 0;
    if (quantity <= 0 || isNaN(quantity)) {
      Notiflix.Notify.failure("Invalid quantity");
      return;
    }

    // existing local data
    const existingItems =
      JSON.parse(localStorage.getItem("requestedItems")) || [];

    // check for spare item in existing data
    const itemIndex = existingItems.findIndex((spare) => spare.Item === itemid);

    if (itemIndex >= 0) {
      // update the count if it exists in localstorage
      existingItems[itemIndex].Quantity += quantity;
    } else {
      // new item to localstorage
      existingItems.push({
        Item: itemid,
        Quantity: quantity,
        Image: spareimg,
        ItemName: sparename,
      });
    }

    // update the localstorage
    localStorage.setItem("requestedItems", JSON.stringify(existingItems));
    inputElement.value = "";

    Notiflix.Notify.success("Item added successfully!");
  };

  const handleRemoveItem = (itemid) => {
    const updatedLocalStorage = localItems.filter(
      (spare) => spare.Item !== itemid
    );
    localStorage.setItem("requestedItems", JSON.stringify(updatedLocalStorage));

    setLocalItems(updatedLocalStorage);
  };

  const handleOpenWarehouseDropdown = () => {
    setShow(false); // close drawer
    setIsModalOpen(true);
  };

  const handleDispatchItems = (id) => {
    if (reqStore && reqStore != null) {
      Notiflix.Loading.dots("Please Wait...");
      PostApiCall.postRequest(
        {
          reqid: id,
          status: "Dispatched",
          updatedby: login[0]?.FLD_User_Id,
          storeid: reqStore,
        },
        "DispatchItems"
      ).then((results) =>
        results.json().then((obj) => {
          try {
            if (results.status === 200 || results.status === 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Items Dispatched Successfully!");
              // clear the states
              setTempDispatchData([]);
              getSpareRequests();
              handlecloseDispatchMmodal();
            } else {
              Notiflix.Notify.failure(obj.data);
              Notiflix.Loading.remove();
            }
          } catch (error) {
            console.log(error);
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please select a store to dispatch items from");
    }
  };

  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          {/* spare request form */}
          {showForm ? (
            <>
              <div className="col-12 px-0">
                <div className="default-card default-card-border">
                  <div className="card-heading">
                    <h4>Spares Request</h4>
                  </div>
                  <div className="row">
                    {spares
                      ?.filter((spare) => spare.FLD_Enabled_Flag == "Active")
                      ?.map((spare) => {
                        return (
                          <div
                            key={spare.FLD_Item_Spares_Id}
                            className="col-6 col-lg-2 mb-3"
                          >
                            <div className="border spare-request-card d-flex flex-column justify-content-between">
                              <div>
                                <img
                                  src={spare?.FLD_Item_Spare_Image || NoImg}
                                  className="img-fluid w-100 mb-3"
                                  alt=""
                                />
                                <h6 className="mb-2">
                                  {spare.FLD_Item_Spare_Name}
                                </h6>
                                <label
                                  htmlFor={`quantity-${spare.FLD_Item_Spares_Id}`}
                                  className="mb-1"
                                >
                                  Quantity
                                </label>
                                <Input
                                  id={`quantity-${spare.FLD_Item_Spares_Id}`}
                                  size="small"
                                  allowClear
                                  className="input-box p-1"
                                />
                              </div>
                              <button
                                className="default-btn fs-6 border-0 mt-3 mt-lg-2"
                                onClick={() => {
                                  handleItemToLocal(
                                    spare.FLD_Item_Spares_Id,
                                    spare.FLD_Item_Spare_Image,
                                    spare.FLD_Item_Spare_Name
                                  );
                                }}
                              >
                                Add Item(s)
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    <div className="col-12 sticky-bottom bg-white">
                      <div className="btn-border mt-2 py-3 text-end align-items-center d-flex justify-content-end">
                        <Button
                          onClick={() => {
                            handleToggleView("Back");
                          }}
                          view
                          className="default-btn border-0 d-none d-lg-inline-block"
                        >
                          Go Back
                        </Button>
                        <Link
                          to="/spare-request-list"
                          className="default-btn d-inline-block d-lg-none go-back-btn"
                        >
                          Go Back
                        </Link>
                        <Button
                          onClick={() => handleShow()}
                          className="default-btn border-0 ms-3 ms-lg-4"
                        >
                          View Added Items
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* side drawer to show added items */}
              <Offcanvas
                placement="end"
                show={show}
                onHide={handleClose}
                className="view-added-spares-modal"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Requested Items</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {localItems.length === 0 ? (
                    <p>No items added yet!</p>
                  ) : (
                    <ul>
                      {localItems.map((item, index) => (
                        <li
                          key={index}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={item.Image || "path/to/default-image.jpg"}
                              alt={item.Name}
                              className="img-fluid"
                              style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "10px",
                              }}
                            />
                            <div>
                              <p className="text-dark mb-0 fw-bold">
                                {item.ItemName}
                              </p>
                              <span className="text-dark">
                                Quantity: {item.Quantity}
                              </span>
                            </div>
                          </div>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveItem(item.Item)}
                          >
                            <MdDelete />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
                </Offcanvas.Body>
                {localItems.length > 0 && (
                  <div className="mt-4 d-grid send-spare-off-btn">
                    <Button
                      size="lg"
                      className="border-0 default-btn"
                      // onClick={() => {
                      //   sendSpareRequest(localItems);
                      //   setIsModalOpen(true);
                      // }}
                      onClick={() =>
                        login[0].FLD_User_Type == 4
                          ? handleOpenWarehouseDropdown()
                          : sendSpareRequest(localItems)
                      }
                    >
                      {login[0].FLD_User_Type == 4
                        ? "Add to Store"
                        : "Send Request"}
                    </Button>
                  </div>
                )}
              </Offcanvas>
            </>
          ) : (
            // spare request listing
            <div className="col-12 px-0">
              <div className="default-card default-card-border mt-2">
                <div className="card-heading d-flex justify-content-between align-items-lg-center gap-3 flex-column flex-lg-row">
                  <h4 className="mb-0">
                    {login[0]?.FLD_User_Type == 4 ||
                    login[0]?.FLD_User_Type == 3
                      ? "Spare Request List (Towards Store)"
                      : "Spare Request List"}
                  </h4>
                  <div className="mb-2 mb-lg-0">
                    <button
                      className="default-btn fs-6 border-0"
                      onClick={() => {
                        handleToggleView("Form");
                      }}
                    >
                      Request A Spare
                    </button>
                  </div>
                </div>
                <div className="job-card-list-table">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={requestedSpare}
                    className="operation-expense-table-size spare-request-table"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Modal to select warehouse and store to add spares from warehouse */}
        <Modal
          title="Request Spares"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Send Request"
          zIndex={1000}
        >
          <Select
            size="middle"
            className="input-box"
            placeholder="Choose Warehouse"
            onChange={(value) => setReqWarehouse(value)}
          >
            {warehouseList.map((warehouse) => (
              <Select.Option
                key={warehouse.FLD_Warehouse_Id}
                value={warehouse.FLD_Warehouse_Id}
              >
                {warehouse.FLD_Warehouse_Name}
              </Select.Option>
            ))}
          </Select>
          <Select
            size="middle"
            className="input-box"
            placeholder="Choose Store"
            onChange={(value) => setReqStore(value)}
          >
            {storeList.map((store) => (
              <Select.Option
                key={store.FLD_Store_Id}
                value={store.FLD_Store_Id}
              >
                {store.FLD_Store_Name}
              </Select.Option>
            ))}
          </Select>
        </Modal>

        {/* Modal for dispatch */}
        <Offcanvas
          placement="end"
          show={showDispatchModal}
          onHide={handlecloseDispatchMmodal}
          className="view-added-spares-modal"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Dispatch Items</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {console.log(tempDispatchData)}
            {tempDispatchData?.length === 0 ? (
              <p>No items added yet!</p>
            ) : (
              <ul>
                {tempDispatchData &&
                  tempDispatchData?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="d-flex justify-content-between align-items-center mb-3"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={
                              item.FLD_Item_Spare_Image ||
                              "path/to/default-image.jpg"
                            }
                            alt={item?.Name}
                            className="img-fluid"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginRight: "10px",
                            }}
                          />
                          <div>
                            <strong>{item?.FLD_Item_Spare_Name}</strong>
                            <br />
                            <span>Quantity: {item?.FLD_Spares_Quantity}</span>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
            <Select
              size="large"
              className="input-box"
              placeholder="Choose Store"
              style={{
                width: "100%",
              }}
              onChange={(value) => setReqStore(value)}
            >
              {storeList.map((store) => (
                <Select.Option
                  key={store.FLD_Store_Id}
                  value={store.FLD_Store_Id}
                >
                  {store.FLD_Store_Name}
                </Select.Option>
              ))}
            </Select>
            <div className="mt-4 d-grid sticky-bottom">
              <Button
                size="lg"
                className="border-0 default-btn"
                onClick={() => handleDispatchItems(reqId)}
              >
                Dispatch Items
              </Button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}
