import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, message, Select, Switch, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./ItemManagement.css";

// For integrate company form using react UseForm
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import { store } from "../../context/Store";
import PostApiCall from "../../PostApi";
import ItemSparesMasterList from "./ItemSparesMasterList";

// For image upload preview image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function ItemSparesMaster() {
  // const [itemspareid, setItemspareid] = useState(null); // always null for payload
  const [status, setStatus] = useState(false);
  const { brandUniqId } = useContext(store);

  // For upload images
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [itemSpareList, setItemSpareList] = useState([]);
  const [colors, setColors] = useState([]);

  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.floor(100000 + Math.random() * 900000))
  );
  const [stores, setStores] = useState([]);

  const getItemColors = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetItemColors").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setColors(obj.data);
          }
        })
    );
  };
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getItemSpares(); // store list
    getSpareItems(); // spares list
    getItemColors(); // color for dropdown
  }, []);

  const getSpareItems = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetSpareItemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setItemSpareList(obj.data);
          }
        })
    );
  };

  const getItemSpares = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetStoreList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setStores(
              obj.data.filter((store) => store.FLD_Enabled_Flag == "Active")
            );
          }
        })
    );
  };

  // Send save data
  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        itemspareid: null,
        itemsparename: data.itemsparename,
        sku: data.sku,
        brandid: data.brandid,
        image: imageUrl,
        spareitemtype: data.spareitemtype,
        unit: data.unit,
        weight: data.weight,
        stockinhand: 1, // removed field data
        color: data.colorname,
        storename: null, // removed from add spare
        measureunit: data.unit,
        status: status ? "Active" : "Inactive",
      },
      "AddSpareitem"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("Item spare added sucessfully.");
          reset({
            itemsparename: "",
            sku: "",
            brandid: "",
            spareitemtype: "",
            unit: "",
            weight: "",
            color: "",
            // storename: "",
            unit: "",
          });
          handleStatusChange(false)
          setStatus(false);
          setImageUrl("");
          getSpareItems();
        } else {
          Notiflix.Notify.failure("Something went wrong");
        }
      })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };

  // Handle Image on server
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Item Image
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "itemSpareMasterImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="margin-left">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-0 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Add New Spares</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-2 pb-3">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader w-100"
                    showUploadList={false}
                    customRequest={onaction}
                    beforeUpload={beforeUpload}
                  >
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                    {imageUrl === "" && uploadButton}
                  </Upload>
                </div>
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-lg-4 pb-3">
                      <label htmlFor="sku">Item SKU</label>
                      <Input
                        size="large"
                        // placeholder="Item SKU"
                        allowClear
                        value={watch("sku") || ""}
                        className="input-box"
                        id="sku"
                        {...register("sku")}
                        onChange={handleChange("sku")}
                      />
                    </div>
                    <div className="col-lg-4 pb-3">
                      <label htmlFor="spareitemtype">
                        Spare Type <span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        className="input-box"
                        placeholder="Choose Spare Type"
                        name="spareitemtype"
                        value={watch("spareitemtype") || ""}
                        id="spareitemtype"
                        {...register("spareitemtype", {
                          required: "Spare type is required",
                        })}
                        onChange={(value) => {
                          setValue("spareitemtype", value);
                          clearErrors("spareitemtype");
                        }}
                        options={[
                          // { value: "", label: "Choose Spare Type" },
                          { value: "Electric", label: "Electric" },
                          { value: "Windproof", label: "Windproof" },
                          { value: "Flint", label: "Flint" },
                        ]}
                      />
                      {errors.spareitemtype && (
                        <p className="text-danger">
                          {errors.spareitemtype.message}
                        </p>
                      )}
                    </div>

                    {/* <div className="col-lg-4 pb-3">
                      <label htmlFor="storename" >Store Name <span className="text-danger">*</span></label>
                      <Select
                        size="large"
                        className="input-box"
                        name="storename"
                        id="storename"
                        value={watch("storename") || ""}
                        placeholder="Choose Store"
                        {...register("storename", {
                          required: "Store name is required",
                        })}
                        onChange={(value) => {
                          setValue("storename", value);
                          clearErrors("storename");
                        }}
                      >
                        {stores.map((store) => (
                          <Select.Option key={store.FLD_Store_Id} value={store.FLD_Store_Id}>
                            {store.FLD_Store_Name}
                          </Select.Option>
                        ))}
                      </Select>

                      {errors.storename && <p className="text-danger">{errors.storename.message}</p>}
                    </div> */}

                    <div className="col-lg-4 pb-3">
                      <label htmlFor="brand">
                        Brand Name <span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        className="input-box"
                        placeholder="Choose Brand"
                        value={watch("brandid") || ""}
                        id="brand"
                        {...register("brandid", {
                          required: "Brand is required",
                        })}
                        onChange={(value) => {
                          setValue("brandid", value);
                          clearErrors("brandid");
                        }}
                        options={[
                          ...(brandUniqId?.map((brand) => ({
                            value: brand?.FLD_Brand_Id,
                            label: brand?.FLD_Brand_Name,
                          })) || []),
                        ]}
                      />
                      {errors.brandid && (
                        <p className="text-danger">{errors.brandid.message}</p>
                      )}
                    </div>
                    <div className="col-lg-4 pb-3">
                      <label htmlFor="itemsparename">
                        Spare Name <span className="text-danger">*</span>
                      </label>
                      <Input
                        size="large"
                        // placeholder="Spare Name"
                        allowClear
                        className="input-box"
                        value={watch("itemsparename") || ""}
                        id="itemsparename"
                        {...register("itemsparename", {
                          required: "Spare name is required",
                        })}
                        onChange={handleChange("itemsparename")}
                      />
                      {errors.itemsparename && (
                        <p className="text-danger">
                          {errors.itemsparename.message}
                        </p>
                      )}
                    </div>
                    {/* <div className="col-lg-4 pb-3">
                      <label htmlFor="color">Spare Color</label>
                      <Input
                        size="large"
                        // placeholder="Spare Color"
                        allowClear
                        className="input-box"
                        id="color"
                        value={watch("color") || ""}
                        name="color"
                        {...register("color")}
                        onChange={handleChange("color")}
                      />
                    </div> */}
                    <div className="col-lg-4 pb-3">
                      <label for="colorid">
                        Spare Color <span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        className="input-box"
                        placeholder="Choose Item Color"
                        value={watch("colorname") || ""}
                        {...register("colorname", {
                          required: "color is required",
                        })}
                        onChange={(value) => {
                          setValue("colorname", value);
                          clearErrors("colorname");
                        }}
                        options={[
                          ...(colors?.map((color) => ({
                            value: color?.FLD_Color,
                            label: color?.FLD_Color,
                          })) || []),
                        ]}
                      />
                      {errors.colorname && (
                        <p className="text-danger">
                          {errors.colorname.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-4 pb-3">
                      <label htmlFor="weight">
                        Weight Per Piece <span className="text-danger">*</span>
                      </label>
                      <Input
                        size="large"
                        // placeholder="Weight Per Piece"
                        allowClear
                        className="input-box"
                        id="weight"
                        value={watch("weight") || ""}
                        {...register("weight", {
                          required: "Weight per piece is required",
                        })}
                        onChange={handleChange("weight")}
                      />
                      {errors.weight && (
                        <p className="text-danger">{errors.weight.message}</p>
                      )}
                    </div>
                    <div className="col-lg-4 pb-3">
                      <label htmlFor="unit">
                        Unit of Measurement{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        size="large"
                        className="input-box"
                        id="unit"
                        value={watch("unit") || ""}
                        placeholder="Choose A Unit"
                        {...register("unit", {
                          required: "Unit of measurement is required",
                        })}
                        onChange={(value) => {
                          setValue("unit", value);
                          clearErrors("unit");
                        }}
                        options={[
                          { value: "grams", label: "Grams (g)" },
                          { value: "pcs", label: "Pcs" },
                          // { value: "Flint", label: "Flint" },
                        ]}
                      />
                      {errors.unit && (
                        <p className="text-danger">{errors.unit.message}</p>
                      )}
                    </div>
                    {/* <div className="col-lg-4 pb-3">
                      <Input
                        size="large"
                        placeholder="Stock In Hand"
                        allowClear
                        className="input-box"
                        id="stockinhand"
                        {...register("stockinhand", {
                          required: "Stock in hand is required",
                        })}
                        onChange={handleChange("stockinhand")}
                      />
                      {errors.stockinhand && (
                        <p className="text-danger">
                          {errors.stockinhand.message}
                        </p>
                      )}
                    </div> */}
                    {/* <div className="col-lg-4 pb-3">
                      <label htmlFor="totalcount">Approximate No. of Pieces</label>
                      <Input
                        size="large"
                        allowClear
                        id="totalcount"
                        className="input-box"
                        disabled
                        value={"Approximate No. of Pieces (123456)"}
                      />
                    </div> */}
                  </div>
                </div>

                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={handleStatusChange} checked={status} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div className="ms-auto">
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save Item Spare
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* *** Item spare master list *** */}
        <ItemSparesMasterList
          storesData={stores}
          itemSpareList={itemSpareList}
          getSpareItems={getSpareItems}
          colors={colors}
        />
      </div>
    </div>
  );
}
