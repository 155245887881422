import React, { useState, useContext, useEffect } from "react";
import { Input, Select, Switch } from "antd";
import { Button } from "react-bootstrap";
import JobCardListTable from "./JobCardListTable";

// For integrate company form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { store } from "../../context/Store";

export default function CreateJobCard() {
  const [jobcardid, setJobCardId] = useState(null);
  const [status, setStatus] = useState(false);
  const { brandUniqId } = useContext(store);
  const [itemsname, setItemsName] = useState([]);
  const [colors, setColors] = useState([]); //color dropdown
  const [jobcardlist, setJobCardList] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  // Send save data
  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        jobcardid: jobcardid,
        jobcardnumber: data.jobcardnumber,
        brandid: 0,
        itemid: data.itemid,
        itemqty: data.itemqty,
        color: data.color,
        lightertype: data.lightertype,
        allotedto: data?.allotedto || "",
        allotedby: data.allotedby,
        comments: data.comments,
        allotmentdepartment: data.allotmentdepartment,
        itemqty: data.itemqty,
        status: status ? "Active" : "Inactive",
        action: "Insert",
      },
      "AddJobCard"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          reset({
            itemid: "",
            itemqty: "",
            brandName: "",
            lightertype: "",
            color: null,
            allotedby: "",
            comments: "",
          });
          Notiflix.Notify.success("Job card created sucessfully.");
          getJobCardList();
        } else {
          Notiflix.Notify.failure("Job card creation failed!");
        }
      })
    );
  };

  useEffect(() => {
    getItems();
    getItemColors();
    getJobCardList();
  }, []);

  const getJobCardList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetJobCardList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setJobCardList(obj.data);
          }
        })
    );
  };

  const getItems = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetitemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setItemsName(
              obj.data.filter((item) => item.FLD_Enabled_Flag == "Active")
            );
          }
        })
    );
  };
  const getItemColors = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetItemColors").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setColors(obj.data);
          }
        })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      if (e?.target?.value) {
        clearErrors(field);
        setValue(field, e.target.value);
      } else {
        console.log("error");
      }
    };
  };

  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };

  const getItemAdditionalInfo = (id, type) => {
    const brand = brandUniqId.find((entry) => entry.FLD_Brand_Id === id);
    const brandName = brand.FLD_Brand_Name;
    setValue("lightertype", type);
    setValue("brandname", brandName);
  };

  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Create Job Card</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 pb-3">
                  <label htmlFor="brandname">
                    Item Name<span className="text-danger">*</span>
                  </label>

                  <Select
                    size="large"
                    className="input-box"
                    placeholder="Item Name"
                    {...register("itemid", { required: "Item is required" })}
                    onChange={(value, option) => {
                      const itemBrandId = option.itemBrandId;
                      const lighterType = option.lighterType;
                      getItemAdditionalInfo(itemBrandId, lighterType);
                      setValue("itemid", value);
                      clearErrors("itemid");
                    }}
                  >
                    {itemsname.map((spare) => {
                      const brandname = brandUniqId.find((entry) => entry.FLD_Brand_Id === spare.FLD_Brand_Id);
                      return (

                        <Select.Option
                          key={spare.FLD_Item_Id}
                          value={spare.FLD_Item_Id}
                          itemBrandId={spare.FLD_Brand_Id}
                          lighterType={spare.FLD_Type}
                        >
                          {`${spare.FLD_Item_Name} - ${spare.FLD_Type} - ${brandname.FLD_Brand_Name}`}
                        </Select.Option>
                      )
                    })}
                  </Select>
                  {errors.itemid && (
                    <p className="text-danger">{errors.itemid.message}</p>
                  )}
                </div>
                <div className="col-lg-4 pb-3">
                  <label htmlFor="itemqty">
                    Item Quantity<span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    placeholder="Quantity"
                    allowClear
                    className="input-box"
                    id="itemqty"
                    {...register("itemqty", {
                      required: "Quantity is required",
                    })}
                    onChange={handleChange("itemqty")}
                  />
                  {errors.itemqty && (
                    <p className="text-danger">{errors.itemqty.message}</p>
                  )}
                </div>
                <div className="col-lg-4 pb-3">
                  <label htmlFor="brandname">
                    Brand Name<span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    placeholder="Brand Name"
                    className="input-box"
                    id="brandname"
                    name="brandname"
                    value={watch("brandname")}
                    disabled
                    {...register("brandname")}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <label htmlFor="lightertype">
                    Lighter Type<span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    placeholder="Lighter Type"
                    className="input-box"
                    id="lightertype"
                    name="lightertype"
                    value={watch("lightertype")}
                    disabled
                    {...register("lightertype")}
                  />
                </div>

                <div className="col-lg-4 pb-3">
                  <label htmlFor="itemcolor">
                    Item Color<span className="text-danger">*</span>
                  </label>
                  <Select
                    size="large"
                    className="input-box"
                    placeholder="Item Color"
                    onChange={(value) => {
                      setValue("color", value);
                    }}
                  >
                    {colors.map((colorData) => (
                      <Select.Option
                        key={colorData.FLD_id}
                        value={colorData.FLD_Color}
                      >
                        {colorData.FLD_Color}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className="col-lg-4 pb-3">
                  <label htmlFor="allotedby">
                    Allotted By<span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    placeholder="Allotted By"
                    allowClear
                    className="input-box"
                    id="allotedby"
                    {...register("allotedby", {
                      required: "Alloted by is required",
                    })}
                    onChange={handleChange("allotedby")}
                  />
                  {errors.allotedby && (
                    <p className="text-danger">{errors.allotedby.message}</p>
                  )}
                </div>
                <div className="col-lg-4 pb-3">
                  <label htmlFor="comments">
                    Comments<span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    placeholder="Comments"
                    allowClear
                    className="input-box"
                    id="comments"
                    {...register("comments", {
                      required: "Comments is required",
                    })}
                    onChange={handleChange("comments")}
                  />
                  {errors.comments && (
                    <p className="text-danger">{errors.comments.message}</p>
                  )}
                </div>

                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={handleStatusChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save & Send to Production
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="d-none d-lg-block">
              <JobCardListTable jobcardlist={jobcardlist} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
