import { DatePicker, Input, Select, Switch } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Button } from "react-bootstrap";

import NotificationMasterList from "./NotificationMasterList";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
export default function NotificationMaster() {
  const onChange = (e) => {
    console.log(e);
  };
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  // const onSearch = (value, _e, info) => console.log(info?.source, value);
  return (
    <div className="margin-left">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-0 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Notification</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 pb-3">
                  <label>
                    Notification From <span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    // placeholder="Notification From"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <label>
                    Notification To <span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    // placeholder="Notification To"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <label>
                    Notification Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    size="large"
                    className="input-box"
                    placeholder="Notifiction Type"
                    defaultValue="Notifiction Type"
                    onChange={handleChange}
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "Notifiction Type",
                        label: "Notifiction Type",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  />
                </div>
                <div className="col-lg-12 pb-3">
                  <label>
                    Notification Message <span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    // placeholder="Notification Message"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>

                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={onChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button className="default-btn border-0 mt-3">
                        Save Notification
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* *** Notification Master List *** */}
        <NotificationMasterList />
      </div>
    </div>
  );
}
