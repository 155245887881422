import { Select, Table } from "antd";
import { CSVDownload, CSVLink } from "react-csv";
import { Nav, Tab } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import PostApiCall from "../../PostApi";
export default function WarehouseItemStock() {
  const [activeTab, setActiveTab] = useState("store");
  const [warehouseStock, setWrehouseStock] = useState([]);
  const [filterDropdown, setFilterDropdown] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [filteredId, setFilteredId] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("Store_Stock");

  const [storeStock, setStoreStock] = useState([]);
  const handleChange = (value) => {
    let csvRows;
    let csvCols;

    setFilteredId(value);
    //  dynamic csv data
    if (activeTab == "store") {
      csvCols = ["S No.", "Item Name", "Item Color", "Total Stock"];
      csvRows = storeStock
        .filter((stock) => stock.FLD_Store_Id == value)
        .map((item, index) => [
          index + 1,
          item.spare_name,
          item.spare_color,
          item.quantity,
        ]);
      setCsvFileName("store_stock");
    } else if (activeTab == "warehouse") {
      csvCols = [
        "S No.",
        "Brand",
        "Item Name",
        "Item Color",
        "No. of Pieces",
        "Apx. Box Weight (in Kgs)",
        "Available Boxes",
      ];
      csvRows = warehouseStock
        .filter((warehouse) => warehouse.FLD_Warehouse_Id == value)
        .map((item, index) => [
          index + 1,
          item.BrandName ? item.BrandName : "N/A",
          item.item_name,
          item.item_color,
          item.FLD_Warehouse_Item_Quantity,
          (item?.item_weight * item?.FLD_Warehouse_Item_Quantity) / 100
            ? (item?.item_weight * item?.FLD_Warehouse_Item_Quantity) / 100
            : "N/A",
          item?.FLD_Bags_Cartons,
        ]);
      setCsvFileName("warehouse_stock");
    } else if (activeTab == "readygoods") {
      csvCols = ["S No.", "Stock Type", "Brand", "Item Name", "No. of Pieces"];
      csvRows = warehouseStock
        .filter((warehouse) => warehouse.FLD_Warehouse_Id == value)
        .map((item, index) => [
          index + 1,
          item.FLD_Stock_Type,
          item.BrandName,
          item.item_name,
          item.FLD_Warehouse_Item_Quantity,
        ]);
      setCsvFileName("readygoods_stock");

      setCsvData([csvCols, ...csvRows]);
    }
  };
  useEffect(() => {
    const getStoreList = () => {
      PostApiCall.postRequest({ whereClause: "" }, "GetStoreList").then(
        (results) =>
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              setStoreList(
                obj.data.filter(
                  (storedata) => storedata.FLD_Enabled_Flag == "Active"
                )
              );
              setFilterDropdown(
                obj.data.filter(
                  (storedata) => storedata.FLD_Enabled_Flag == "Active"
                )
              );
            }
          })
      );
    };
    const getWarehouses = () => {
      PostApiCall.postRequest(
        { whereClause: "" },
        "GetWarehouseMasterList"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setWarehouseList(obj.data);
          }
        })
      );
    };
    PostApiCall.postRequest({}, "GetWarehouseItemStockList").then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setWrehouseStock(obj.data);
        }
      })
    );
    PostApiCall.postRequest({}, "GetItemSpareStockList").then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setStoreStock(obj.data);
        }
      })
    );
    getStoreList();
    getWarehouses();
  }, []);
  dayjs.extend(customParseFormat);

  // warehouse cols
  const warehouseStockCols = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Brand",
      dataIndex: "brandname",
      key: "brandname",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Item Color",
      dataIndex: "itemcolor",
      key: "itemcolor",
    },
    {
      title: "No. of Pieces",
      dataIndex: "totalStock",
      key: "totalStock",
    },
    {
      title: "Apx. Box Weight (in kgs)",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "No. of Boxes/Cartons",
      dataIndex: "boxCount",
      key: "boxCount",
    },
  ];

  // Ready goods cols
  const readyGoodsCols = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Stock Type",
      dataIndex: "stocktype",
      key: "stocktype",
    },
    {
      title: "Brand",
      dataIndex: "brandname",
      key: "brandname",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "No. of Cartons/Bags",
      dataIndex: "bagcount",
      key: "bagcount",
    },
    {
      title: "No. of Pieces",
      dataIndex: "totalStock",
      key: "totalStock",
    },
  ];

  // store cols
  const columns = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Item Color",
      dataIndex: "itemcolor",
      key: "itemcolor",
    },
    {
      title: "No. of Pieces",
      dataIndex: "totalStock",
      key: "totalStock",
    },
  ];

  // warehouse stock row
  const mappedData = warehouseStock
    .filter(
      (warehouse) =>
        warehouse.FLD_Warehouse_Id == filteredId &&
        warehouse.FLD_Stock_Type === "Spare"
    )
    .map((warehouseData, index) => {
      return {
        key: index + 1,
        serial: index + 1,
        itemName: warehouseData?.item_name,
        brandname: warehouseData?.BrandName,
        itemcolor: warehouseData?.item_color,
        totalStock: warehouseData?.FLD_Warehouse_Item_Quantity,
        weight:
          (warehouseData?.item_weight *
            warehouseData?.FLD_Warehouse_Item_Quantity) /
          100,
        boxCount: warehouseData?.FLD_Bags_Cartons,
      };
    });

  // store stock row
  const mappedStoreStock = storeStock
    .filter((stock) => stock.FLD_Store_Id == filteredId)
    .map((stock, index) => {
      return {
        key: index + 1,
        serial: index + 1,
        itemName: stock?.spare_name,
        itemcolor: stock?.spare_color,
        totalStock: stock?.quantity,
      };
    });

  // ready goods row
  const readyGoodsRows = warehouseStock
    .filter(
      (warehouse) =>
        warehouse.FLD_Warehouse_Id == filteredId &&
        warehouse.FLD_Stock_Type != "Spare"
    )
    .map((stock, index) => {
      return {
        key: index + 1,
        serial: index + 1,
        stocktype: stock?.FLD_Stock_Type,
        brandname: stock?.BrandName,
        itemName: stock?.item_name,
        bagcount: stock?.item_bagcount,
        totalStock: stock?.FLD_Warehouse_Item_Quantity,
      };
    });

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    if (tab == "warehouse" || tab == "readygoods") {
      setFilterDropdown(warehouseList);
      setFilteredId(null);
    } else {
      setFilterDropdown(storeList);
      setFilteredId(null);
    }
  };

  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-0">
        <div className="row pt-4 mt-4 mb-0 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border job-card-list-table">
              <div className="card-heading">
                <h4>
                  {" "}
                  {activeTab == "store"
                    ? "Store Stock Statement"
                    : "Warehouse Stock Statement"}
                </h4>
              </div>
              <div className="tab-filter">
                <Tab.Container
                  defaultActiveKey="store"
                  onSelect={handleTabSelect}
                >
                  <div className=" mb-4 w-100 flex-wrap gap-4 d-flex justify-content-between align-items-end">
                    <div className="overflow-x-auto">
                    <Nav
                      variant="pills"
                      className="flex-row m-0 w-auto ml-auto"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="store">Store</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="warehouse">Warehouse</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="readygoods">Ready Goods</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    </div>
                    <div className="text-dark d-flex flex-wrap gap-3">
                      <Select
                        value={filteredId}
                        placeholder={
                          activeTab == "store"
                            ? "Choose Store"
                            : "Choose Warehouse"
                        }
                        onChange={handleChange}
                      >
                        {filterDropdown.map((warehouse) => (
                          <Select.Option
                            key={
                              warehouse.FLD_Store_Id
                                ? warehouse.FLD_Store_Id
                                : warehouse.FLD_Warehouse_Id
                            }
                            value={
                              warehouse.FLD_Store_Id
                                ? warehouse.FLD_Store_Id
                                : warehouse.FLD_Warehouse_Id
                            }
                          >
                            {warehouse?.FLD_Store_Name
                              ? warehouse?.FLD_Store_Name
                              : warehouse?.FLD_Warehouse_Name}
                          </Select.Option>
                        ))}
                      </Select>
                      <CSVLink
                        data={csvData ? csvData : ""}
                        filename={csvFileName}
                        className="d-inline"
                      >
                        <button
                          type="button"
                          className="default-btn border-0"
                        >
                          Download CSV
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="store">
                      <Table
                        bordered
                        className="operation-expense-table-size "
                        columns={columns}
                        dataSource={mappedStoreStock}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="warehouse">
                      <Table
                        bordered
                        className="operation-expense-table-size spare-request-table warehouse-request-table"
                        columns={warehouseStockCols}
                        dataSource={mappedData}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="readygoods">
                      <Table
                        bordered
                        className="operation-expense-table-size"
                        columns={readyGoodsCols}
                        dataSource={readyGoodsRows}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
