import { Input, Switch } from "antd";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";

// For integrate company form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import WarehouseMasterList from "./WarehouseMasterList";

export default function WarehouseMaster() {
  const [warehouseid, setWarehouseid] = useState(null);
  const [warehouseList, setWarehouseList] = useState([]);
  const [status, setStatus] = useState(false);
  useEffect(() => {
    getWarehouses();
  }, []);
  const getWarehouses = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetWarehouseMasterList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setWarehouseList(obj.data);
          }
        })
    );
  };
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onChange = (e) => {
    console.log(e);
  };

  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        warehouseid: warehouseid,
        warehousename: data.warehousename,
        status: status ? "Active" : "Inactive",
      },
      "AddWarehouseMaster"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("Warehouse added sucessfully");
          getWarehouses();
          reset({
            warehousename: "",
          });
          setStatus(false);
        } else {
          Notiflix.Notify.failure("Failed to add warehouse!");
        }
      })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };
  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-0 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Add New Warehouse</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-12 pb-3">
                  <label htmlFor="warehousename">
                    Warehouse Name<span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    // placeholder="Warehouse Name"
                    allowClear
                    className="input-box"
                    value={watch("warehousename") || ""}
                    id="warehousename"
                    {...register("warehousename", {
                      required: "Warehousename is required",
                    })}
                    onChange={handleChange("warehousename")}
                  />
                  {errors.storename && (
                    <p className="text-danger">{errors.storename.message}</p>
                  )}
                </div>
                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={handleStatusChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div className="ms-auto">
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save Warehouse
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* **** Add Warehouse Master List **** */}
        <WarehouseMasterList
          warehouseList={warehouseList}
          getWarehouses={getWarehouses}
        />
      </div>
    </div>
  );
}
