import React from "react";
import { CalendarSection } from "../Calendar/Calender";
import { Charts } from "../Chart/Chart";
import { TopHeader } from "../TopHeader/TopHeader";
export default function AdminDashboard() {
  return (
    <>
      <TopHeader />
      <CalendarSection />
      <Charts />
    </>
  );
}
