import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import GetApiCall from "../../GetApi";
export default function ColumnChart() {

  const [chartDataCustomer, setChartDataCustomer] = useState({
    options: {
      chart: {
        height: 280,
        // width: '100%',
        type: "bar",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: 0,
          columnWidth: "50%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["var(--color-theme)", "#9fb87382"],
      fill: {
        colors: ["var(--color-theme)", "#9fb87382"],
      },
      stroke: {
        curve: "smooth",
      },
      // responsive: {
      //     breakpoint: undefined,
      //     options: {},
      // }
      responsive: [
        {
          breakpoint: 1000,
          yaxis: {
            categories: [
              "Jan",
              "Feb",
              "März",
              "Apr",
              "Mai",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Okt",
              "Nov",
              "Dez",
            ],
          },
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: "series1",
        data: [],
      },
      {
        name: "series2",
        data: [],
      },
    ],
  });
  useEffect(() => {
    GetApiCall.getRequest("GetyearlyDsipatchDetails").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var month = [];
          var value = [];
          var sales = 0.0;
          obj.data?.sort((a, b) => a.month_order - b.month_order)?.map((data1, i) => {
            value.push(data1.total_jobcards);
            month.push(data1.month);
            sales = sales + data1.total_sales;
          });
          setChartDataCustomer({
            options: {
              chart: {
                height: 200,
                // width: '100%',
                type: "bar",
                stacked: !0,
                toolbar: {
                  show: 0,
                },
                zoom: {
                  enabled: !0,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: 0,
                  columnWidth: "50%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: 0,
              },
              xaxis: {
                categories: month,
              },
              legend: {
                position: "bottom",
                show: 0,
              },
              colors: ["var(--color-theme)", "#9fb87382"],
              fill: {
                colors: ["var(--color-theme)", "#9fb87382"],
              },
              stroke: {
                curve: "smooth",
              },
              // responsive: {
              //     breakpoint: undefined,
              //     options: {},
              // }
            },
            series: [
              {
                name: "Total Job Cards",
                data: value
              }
            ],
          });

          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
        }
      })
    );
  }, [])

  return (
    <ReactApexChart
      options={chartDataCustomer.options}
      series={chartDataCustomer.series}
      type="bar"
      height={320}
    />
  );
}
