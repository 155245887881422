import React, { useState } from "react";
import "./SideNavbar.css";
import { Sidebar } from "react-pro-sidebar";
import ManagerSideNvbar from "./ManagerSideNvbar";
import SupervisorSideNavbar from "./SupervisorSideNavbar";
import { StoreManagerSidebar } from "./StoreManagerSidebar";
import RejectionSidebar from "./RejectionSidebar";

export const SideNavbar = ({ collapsed, handleSidebarCollapse }) => {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  let renderSideBar;

  switch (login[0]?.FLD_User_Type) {
    case 3:
      renderSideBar = ManagerSideNvbar;
      break;
    case 4:
      renderSideBar = StoreManagerSidebar;
      break;
    case 5:
      renderSideBar = RejectionSidebar;
      break;
    default:
      renderSideBar = SupervisorSideNavbar;
      break;
  }

  return (
    <>
      <div className="sidenavbar">
        <Sidebar collapsed={collapsed}>
          {React.createElement(renderSideBar, {
            handleCollapse: handleSidebarCollapse,
          })}
        </Sidebar>
      </div>
    </>
  );
};
