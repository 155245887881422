import React, { useState, useEffect } from "react";
import { Space, Table, Tag, Input } from "antd";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import "./ProductionManagement.css";
import Search from "antd/es/input/Search";
import PostApiCall from "../../PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function JobCardListTable({ jobcardlist }) {
  const [searchInput, setsearchInput] = useState("");
  const [itemsName, setItemsName] = useState({});

  useEffect(() => {
    // Get item name
    PostApiCall.postRequest({ whereClause: "" }, "GetitemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            const items = {};
            for (let i = 0; i < obj.data.length; i++) {
              const item = obj.data[i];
              items[item.FLD_Item_Id] = item.FLD_Item_Name;
            }
            setItemsName(items);
          }
        })
    );

    // Get Job card list
  }, []);

  // Delete job card data
  // const handleDeleteJobcard = (jobCardId) => {
  //   console.log(jobCardId, "Check id");
  //   confirmAlert({
  //     title: "Confirm to Delete",
  //     message: "Are you sure you want to delete this job card?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: async () => {
  //           Notiflix.Loading.dots("");
  //           try {
  //             const results = await PostApiCall.postRequest(
  //               {
  //                 jobcardid: `${jobCardId}`,
  //                 status: "Deleted",
  //               },
  //               "DeleteJobCard"
  //             );
  //             if (results.status === 200 || results.status === 201) {
  //               Notiflix.Notify.success("Job card successfully deleted.");
  //               setJobCardList((prevList) =>
  //                 prevList.filter(
  //                   (jobcarddata) => jobcarddata.FLD_Job_Card_Id !== jobCardId
  //                 )
  //               );
  //             } else {
  //               Notiflix.Notify.failure("Failed to delete the Job card.");
  //             }
  //           } catch (error) {
  //             Notiflix.Notify.failure("An error occurred: " + error.message);
  //           } finally {
  //             Notiflix.Loading.remove();
  //           }
  //         },
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };

  const data = {
    columns: [
      {
        title: "S No.",
        field: "serial",
        width: 50,
        dataIndex: "serial",
        key: "serial",
      },
      {
        title: "Job Card No.",
        field: "jobcardnumber",
        dataIndex: "jobcardnumber",
        key: "jobcardnumber",
        defaultSortOrder: "descend",
        width: 160,
      },
      {
        title: "Date & Time	",
        field: "datetime",
        width: 180,
        dataIndex: "datetime",
        defaultSortOrder: "descend",
        key: "datetime",
      },
      {
        title: "Item Name	",
        field: "itemname",
        width: 150,
        dataIndex: "itemname",
        key: "itemname",
      },
      {
        title: "Quantity",
        field: "quantity",
        width: 150,
        dataIndex: "quantity",
        key: "quantity",
      },
      // {
      //   title: "Alloted To	",
      //   field: "allotedto",
      //   width: 100,
      //   dataIndex: "allotedto",
      //   key: "allotedto",
      // },
      {
        title: "Alloted By	",
        field: "allotedby",
        width: 100,
        dataIndex: "allotedby",
        key: "allotedby",
      },
      // {
      //   title: "Out",
      //   field: "out",
      //   width: 100,
      //   dataIndex: "out",
      //   key: "out",
      // },
      // {
      //   title: "In",
      //   field: "in",
      //   width: 100,
      //   dataIndex: "in",
      //   key: "in",
      // },
      {
        title: "Status",
        field: "status",
        width: 100,
        dataIndex: "status",
        key: "status",
      },
      // {
      //   title: "",
      //   field: "action",
      //   width: 100,
      //   dataIndex: "action",
      //   key: "action",
      // },
    ],
    rows: jobcardlist.map((data, i) => {
      return {
        serial: i + 1,
        jobcardnumber: data.FLD_Job_Card_Number,
        datetime: moment(data.FLD_Date_Time).format("DD-MM-YYYY"),
        itemname: itemsName[data.FLD_Item_Id] || "",
        quantity: data.FLD_Item_Qty,
        // allotedto: data.FLD_Alloted_to,
        allotedby: data.FLD_Alloted_By,
        // out: data.out,
        // in: data.in,
        status: data.FLD_Enabled_Flag,
        // action: (
        //   <>
        //     <div className="d-flex">
        //       <Space size="middle" className="table-btn">
        //         {/* <button>
        //           <FiEdit />
        //         </button> */}
        //         <button
        //           onClick={() => handleDeleteJobcard(data.FLD_Job_Card_Id)}
        //         >
        //           <MdDelete />
        //         </button>
        //       </Space>
        //     </div>
        //   </>
        // ),
      };
    }),
  };

  const seachBarHandler = (e) => {
    setsearchInput(e.target.value);
  };

  return (
    <div className="default-card default-card-border mt-2">
      <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
        <h4>List of Job Cards ({jobcardlist.length} Nos.)</h4>
        <div className="search-box w-50">
          <Search placeholder="Search Job Card" allowClear className="w-100" />
        </div>
      </div>
      <div className="overflow-x-auto job-card-list-table">
        {/* <Input.Search
          className="mb-3"
          placeholder="search here...."
          onChange={(e) => seachBarHandler(e)}
          value={searchInput}
        /> */}
        <Table
          className="operation-expense-table-size"
          columns={data.columns}
          dataSource={data.rows}
          bordered
        />
      </div>
    </div>
  );
}
