import { Select } from "antd";
import { Nav, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Collapse, Drawer, Modal, Input } from "antd";
import GetApiCall from "../../GetApi";
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
const { Panel } = Collapse;
export default function RejectionPage() {
  const [stages, setStages] = useState([]);
  const [rejectionCards, setRejectionCards] = useState([]);
  const [tempCards, setTempCards] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentDeptId, setCurrentDeptId] = useState(2);
  const [showOpen, setShowOpen] = useState(false);
  const [currStage, setCurrStage] = useState("Gas Filling");
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const handleChangeinput = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };

  const handleCustomSubmit = (lot) => {
    handleSubmit((fieldsData) => addLotToProduction(fieldsData, lot))();
  };

  const addLotToProduction = (fieldsData, lot) => {
    const deadstock = watch("deadstock") || 0;
    PostApiCall.postRequest(
      {
        tempcardid: lot.id,
        deptseq: fieldsData.dept,
        repairedqty: lot.rejections - deadstock,
        deadstock: parseInt(fieldsData.deadstock),
        allotedby: fieldsData.allotedby,
      },
      "SendRejectionsToProduction"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Job Card sent to production");
          getTempCards();
          reset();
        } else {
          Notiflix.Notify.failure("Something went wrong");
        }
      })
    );
  };

  useEffect(() => {
    const getStages = () => {
      PostApiCall.postRequest({ whereClause: "" }, "GetDepartment").then(
        (results) =>
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              setStages(
                obj.data
                  .sort((a, b) => a.Fld_Sequence - b.Fld_Sequence)
                  .filter((stage) => stage.FLD_IsIncludeJobCard == "True")
              );
            }
          })
      );
    };
    getTempCards();
    getRejectedCards();
    getStages();
  }, []);

  const getTempCards = () => {
    let activeCards;
    GetApiCall.getRequest("GetTempCards").then((resultdes) => {
      resultdes.json().then((obj) => {
        activeCards = obj.data.filter(
          (card) => card.status === "JobcardCreated"
        );
        setTempCards(activeCards);
        if (activeCards.length > 0) {
          setShowOpen(true);
        } else {
          setShowOpen(false);
        }
      });
    });
  };

  const getRejectedCards = (rejectionStage = "Gas Filling") => {
    PostApiCall.postRequest(
      { stage: rejectionStage },
      "GetRejectionsForStage"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setRejectionCards(obj.data);
        }
      })
    );
  };

  // create temp job card before sending to production
  const CreateTempCard = (itemId, rejectedCount, itemcolor) => {
    PostApiCall.postRequest(
      {
        itemid: itemId,
        quantity: rejectedCount,
        color: itemcolor,
        depid: parseInt(currentDeptId),
      },
      "CreateTempCard"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Success!");
          getTempCards();
          getRejectedCards(currStage);
        }
      })
    );
  };

  const handleChangeTab = (optionData) => {
    console.log(optionData, optionData.value, optionData.key);
    setCurrStage(optionData.value);
    getRejectedCards(optionData.value);
    setCurrentDeptId(optionData.key);
  };
  return (
    <>
      <div className="margin-left blank-section job-card-page">
        {console.log(tempCards.length)}
        <div className="container-fluid pt-4 pb-0">
          <div className="row pt-4 mt-4 mb-0 px-0">
            <div className="col-12 px-0">
              <div className="default-card default-card-border rejection-box">
                <div className="card-heading">
                  <div className="d-flex justify-content-between align-items-end">
                    <h4>Rejections</h4>
                    {/* tabs for stages */}
                    <div className="tab-filter ps-0 d-none d-lg-block">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="2"
                        onSelect={(e) => setCurrentDeptId(e)}
                      >
                        <Nav variant="pills" className="flex-row">
                          {stages.map((stageData) => {
                            return (
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={stageData.FLD_Department_Id}
                                  onClick={() =>
                                    getRejectedCards(
                                      stageData.FLD_Department_Name
                                    )
                                  }
                                >
                                  {stageData.FLD_Department_Name}
                                </Nav.Link>
                              </Nav.Item>
                            );
                          })}
                        </Nav>
                      </Tab.Container>
                    </div>
                    {/* mobile dropdown for stages */}
                    <div className="w-50 d-flex justify-content-end d-lg-none">
                      <Select
                        labelInValue
                        showSearch
                        className=""
                        placeholder="Select a stage"
                        optionFilterProp="label"
                        onChange={handleChangeTab}
                      >
                        {stages.map((stageData) => (
                          <Select.Option
                            key={stageData.FLD_Department_Id}
                            value={stageData.FLD_Department_Name}
                          >
                            {stageData.FLD_Department_Name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {rejectionCards.length ? (
                    rejectionCards?.map((rejCard) => {
                      return (
                        <div className="col-lg-4 px-lg-2 mb-3">
                          <div className="job-card-box border">
                            <div className="d-flex justify-content-between align-items-center box pb-3 w-100">
                              <div className="d-flex ">
                                <img
                                  src={rejCard.item_image}
                                  className="img-fluid"
                                />
                                <p className="card-number me-2">
                                  {rejCard.item_name}{" "}
                                </p>
                              </div>
                              <div
                                className="jobcard-color-span"
                                style={{ background: `${rejCard.color}` }}
                              />
                            </div>
                            <div className="box justify-content-between text-dark">
                              <p>Rejection :</p>
                              <p>{rejCard.rejected_items}</p>
                            </div>
                            <p className="text-warning">
                              Once completed, this action cannot be undone.
                            </p>
                            <div className="text-end mt-3">
                              <button
                                type="submit"
                                className="default-btn border-0"
                                onClick={() =>
                                  CreateTempCard(
                                    rejCard.FLD_Item_Id,
                                    rejCard.rejected_items,
                                    rejCard.color
                                  )
                                }
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center">
                      No Rejections available at this stage.
                    </p>
                  )}
                </div>
                {showOpen && (
                  <div className="fixed-view-job-card-btn text-center bg-white">
                    <span
                      className="default-btn text-center fs-5 d-inline-block"
                      onClick={() => {
                        showDrawer();
                      }}
                    >
                      View Job Cards
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Current Job Cards"
        onClose={onClose}
        open={openDrawer}
        placement="bottom"
        className="tray-shifting-drawer job-card-page supervisor-filter-drawer tray-shifting-page"
      >
        <div className="container-fluid pb-2">
          <div className="row mb-4 px-0">
            {tempCards.map((lot, lotIndex) => {
              return (
                <div className="col-lg-4 px-0 px-lg-2">
                  <Collapse accordion>
                    <Panel
                      header={
                        <div className="job-card-box p-0 border-0">
                          <div className="box border-0 mb-2">
                            <div className="d-flex align-items-center">
                              <img src={lot.item_image} className="img-fluid" />
                              <p className="card-number">
                                {lot?.jobcard_number}
                              </p>
                              <div
                                className="jobcard-color-span"
                                style={{ background: `${lot.color}` }}
                              />
                            </div>
                          </div>
                          <div className="box content">
                            <p>
                              <span>Rejection Stage: </span>
                              {lot?.department}
                            </p>
                          </div>
                          <div className="box content">
                            <p>
                              <span>Total Quantity : </span>
                              {lot?.rejections}
                            </p>
                          </div>
                        </div>
                      }
                      key={lotIndex}
                    >
                      <>
                        <div className="stage-input">
                          <div className="box">
                            <span>Quantity In :</span>
                            <p>{lot.rejections}</p>
                          </div>
                          <span>Dead Stock</span>
                          <div className="w-100">
                            <Input
                              type="number"
                              className="w-100 input-box"
                              allowClear
                              value={watch("deadstock")}
                              id="deadstock"
                              {...register("deadstock", {
                                required: "This field is required",
                              })}
                              onChange={handleChangeinput("deadstock")}
                            />
                            {errors.deadstock && (
                              <p className="text-danger">
                                {errors.deadstock.message}
                              </p>
                            )}
                          </div>
                          <div className="box">
                            <span>Repaired</span>
                            <Input
                              allowClear
                              disabled
                              className="input-box"
                              value={
                                lot.rejections - watch("deadstock")
                                  ? lot.rejections - watch("deadstock")
                                  : "Will be calculated automatically"
                              }
                            />
                          </div>
                          <div className="box">
                            <span>Alloted By</span>
                            <Input
                              allowClear
                              className="input-box w-100"
                              value={watch("allotedby")}
                              id="allotedby"
                              {...register("allotedby", {
                                required: "This field is required",
                              })}
                              onChange={handleChangeinput("allotedby")}
                            />
                            {errors.allotedby && (
                              <p className="text-danger">
                                {errors.allotedby.message}
                              </p>
                            )}
                          </div>
                          <div className="box">
                            <span>
                              <label htmlFor="dept">
                                Assign to Stage{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </span>
                            <div className="w-100">
                              <Select
                                size="large"
                                className="input-box input-box-select w-100"
                                id="dept"
                                placeholder="Choose A Dept."
                                value={watch("dept")}
                                {...register("dept", {
                                  required: "This field is required",
                                })}
                                onChange={(value) => {
                                  setValue("dept", value);
                                  clearErrors("dept");
                                }}
                              >
                                {stages.map((stageData) => (
                                  <Select.Option
                                    key={stageData.FLD_Department_Id}
                                    value={stageData.Fld_Sequence}
                                  >
                                    {stageData.FLD_Department_Name}
                                  </Select.Option>
                                ))}
                              </Select>
                              {errors.dept && (
                                <p className="text-danger">
                                  {errors.dept.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="mt-3 text-end">
                            <span
                              className="default-btn"
                              onClick={() => handleCustomSubmit(lot)}
                            >
                              Send Card to Production{" "}
                            </span>
                          </div>
                        </div>
                      </>
                    </Panel>
                  </Collapse>
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </>
  );
}
