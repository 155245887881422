/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import "../Navbar/Navbar.css";
import { SideNavbar } from "../SideNavbar/SideNavbar"; // dynamic

import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import PostApiCall from "../../PostApi";
import { store } from "../../context/Store";
import LogoutModal from "../LoginPage/Logout";
import Notiflix from "notiflix";
import { FaUser } from "react-icons/fa";
import ChangePasswordModal from "../ChangePassword/ChangePasswordModal";

export const Navbar = () => {
  const [collapsed, setCollapsed] = React.useState(true);
  const [bodyCollapsed, setBodyCollapsed] = React.useState(false);

  // For Logout
  const [canLogout, setCanLogout] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const navigate = useNavigate();

  // For fetch brand list and brand id
  const { setBrandId, setBrandList, setLoginDetails, loginDetails } =
    useContext(store);
  useEffect(() => {
    if (localStorage.getItem("LoginDetail")) {
      setLoginDetails(JSON.parse(localStorage.getItem("LoginDetail")));
    }
    getBrands();
  }, []);

  const getBrands = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetBrandList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setBrandList(obj.data);
            setBrandId(
              obj.data.filter((brand) => brand.FLD_Enabled_Flag === "Active")
            );
          }
        })
    );
  };
  // Function for logout
  const onLogout = () => {
    Notiflix.Loading.dots();
    if (localStorage.getItem("LoginDetail") !== null) {
      PostApiCall.postRequest(
        { userid: loginDetails[0].FLD_User_Id },
        "LogOut"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            localStorage.clear();
            setCanLogout(false);
            navigate("/");
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Something went wrong");
      Notiflix.Loading.remove();
    }
  };
  const onHideChangePassword = () => {
    setChangePassword(false);
  };
  const handleToggleLogout = () => {
    setCanLogout(false);
  };

  if (bodyCollapsed === true) {
    var bodyElement = document.getElementsByTagName("html")[0];
    bodyElement.classList.add("bodycollapse");
  } else {
    bodyElement = document.getElementsByTagName("html")[0];
    bodyElement.classList.remove("bodycollapse");
  }
  const handleSidebarCollapse = () => {
    setCollapsed(!collapsed);
    setBodyCollapsed(!bodyCollapsed);
  };

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <>
      <div className="header ">
        <div className="header-content container-fluid">
          <nav className="navbar navbar-expand w-100">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div
                  className="nav-bar-link nav-bar-link-open me-3"
                  onClick={() => handleSidebarCollapse()}
                >
                  <i className="fa-solid fa-bars"></i>
                </div>
                <div
                  className="nav-bar-link nav-bar-link-close me-3"
                  onClick={() => handleSidebarCollapse()}
                >
                  <i className="fa-solid fa-xmark"></i>
                </div>
              </div>
            </div>
            <div className="header-left">
              <div className="dashboard_bar">
                <Link to={"/dashboard"}>
                  <h3>Shivaay International</h3>
                </Link>
              </div>
            </div>
            <ul className="navbar-nav header-right w-100 topbar-list">
              <div
                className="nav-bar-link me-2 d-none d-lg-flex"
                onClick={toggleFullscreen}
              >
                <i className="fa-solid fa-expand"></i>
              </div>

              <Dropdown className="nav-bar-link p-0">
                <Dropdown.Toggle id="dropdown-basic">
                  <FaUser />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="d-flex align-items-center border-bottom pt-1 pb-2 ps-3">
                    <FaUser />
                    <span className="ms-2">
                      {loginDetails[0]?.FLD_Designation}
                    </span>
                  </div>
                  <Dropdown.Item onClick={() => setChangePassword(true)}>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="border-top"
                    onClick={() => setCanLogout(true)}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </nav>
        </div>
      </div>
      <SideNavbar
        collapsed={collapsed}
        handleSidebarCollapse={handleSidebarCollapse}
      />

      {/* For Logout modal */}
      <LogoutModal
        show={canLogout}
        onHide={handleToggleLogout}
        onLogout={onLogout}
      />
      <ChangePasswordModal
        showChangePassword={changePassword}
        onHideChangePassword={onHideChangePassword}
      />
    </>
  );
};
