import "./SideNavbar.css";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from "react-pro-sidebar";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { FaAddressCard } from "react-icons/fa";
export default function RejectionSidebar({ handleCollapse }) {
  return (
    <>
      <Menu>
        <Link
          to={"/rejection-master-dashboard"}
          onClick={() => handleCollapse()}
        >
          <MenuItem className="sidebar-menuitem">
            <BsFillHouseDoorFill className="sidebar-icon" /> Dashboard
          </MenuItem>
        </Link>
        <Link to={"/rejections"} onClick={() => handleCollapse()}>
          <MenuItem className="sidebar-menuitem">
            <FaAddressCard className="sidebar-icon" /> Rejection Management
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
}
