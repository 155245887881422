import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import Assembly from "../../assets/img/icons/Assembly.png";
import Completed from "../../assets/img/icons/completed.png";
import FlameTest1 from "../../assets/img/icons/flametest-1.png";
import FlameTest2 from "../../assets/img/icons/flametest-2.png";
import GasFilling from "../../assets/img/icons/gas-filling.png";
import Packaging from "../../assets/img/icons/Packaging.png";
import QualityCheck from "../../assets/img/icons/Quality-Check.png";
import TrayShifting from "../../assets/img/icons/Tray-Shifting.png";
import PostApiCall from "../../PostApi";
import "../SupervisorDashboard/SupervisorDashboard.css";

export const CalendarSection = () => {
  const [dashboardChipData, setDashboardChipData] = useState([]);
  useEffect(() => {
    getDashboardChipCount();
  }, []);
  const getDashboardChipCount = () => {
    try {
      PostApiCall.postRequest({}, "GetDashboardCount").then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setDashboardChipData(obj.data);
          } else {
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getStageCount = (stageName) => {
    const stageCount = dashboardChipData
      .filter((stage) => stage.FLD_Active_Stage === stageName)
      .reduce((total, stage) => total + stage.stage_count, 0);

    return stageCount ? stageCount : 0;
  };
  return (
    <div className="margin-left supervisor-dashboard">
      <div className="container-fluid">
        <div className="row mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
                <h4>Active Job Cards</h4>
              </div>
              <div className="jobcard-count-box">
                <div className="jobcard-count-card">
                  <img
                    src={GasFilling}
                    className="img-fluid"
                    alt="Gas Filling"
                  />
                  <p>{getStageCount("Gas Filling")}</p>
                  <span>Gas Filling</span>
                </div>
                <div className="jobcard-count-card">
                  <img
                    src={FlameTest1}
                    className="img-fluid"
                    alt="Gas Filling"
                  />
                  <p>{getStageCount("Flame Testing 1")} </p>
                  <span>Flame Test 1</span>
                </div>
                <div className="jobcard-count-card">
                  <img
                    src={FlameTest2}
                    className="img-fluid"
                    alt="Gas Filling"
                  />
                  <p>{getStageCount("Flame Testing 2")}</p>
                  <span>Flame Test 2</span>
                </div>
                <div className="jobcard-count-card">
                  <img
                    src={Assembly}
                    className="img-fluid"
                    alt="Gas Filling"
                  />
                  <p>{getStageCount("Assembly")}</p>
                  <span>Assembly</span>
                </div>
                <div className="jobcard-count-card">
                  <img
                    src={QualityCheck}
                    className="img-fluid"
                    alt="Gas Filling"
                  />
                  <p>{getStageCount("Quality Check")}</p>
                  <span>Quality Check</span>
                </div>
                <div className="jobcard-count-card">
                  <img
                    src={TrayShifting}
                    className="img-fluid"
                    alt="Gas Filling"
                  />
                  <p>{getStageCount("Tray Shifting")}</p>
                  <span>Tray Shifting</span>
                </div>
                <div className="jobcard-count-card">
                  <img
                    src={Packaging}
                    className="img-fluid"
                    alt="Gas Filling"
                  />
                  <p>{getStageCount("Packaging")}</p>
                  <span>Packaging</span>
                </div>
                <div className="jobcard-count-card">
                  <img
                    src={Completed}
                    className="img-fluid"
                    alt="Gas Filling"
                  />
                  <p>{getStageCount("Completed")}</p>
                  <span>Completed</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
