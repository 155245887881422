import React, { useState, useEffect } from "react";
import "./StaffManagement.css";
import { Button } from "react-bootstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, message, Upload, Select, Switch, DatePicker } from "antd";
import Search from "antd/es/input/Search";
import TextArea from "antd/es/input/TextArea";

// For integrate company form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import GetApiCall from "../../GetApi";
import Notiflix from "notiflix";
import StaffMasterList from "./StaffMasterList";
import StaffMasterListNew from "./StaffMasterListNew";
import moment from "moment";

// For image upload preview image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function StaffMaster() {
  const [userid, setUserid] = useState(null);
  const [status, setStatus] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [showList, setShowList] = useState(true);
  const [selectedStaff, setSelectedStaff] = useState(null);

  // For upload images
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.floor(100000 + Math.random() * 900000))
  );
  const [departments, setDeprtments] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  // Get User Types
  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetUserType").then((resultdes) => {
      resultdes.json().then((obj) => {
        setUserTypes(obj.data);
        Notiflix.Loading.remove();
      });
    });
    getDepartmentData();
  }, []);

  const getDepartmentData = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetDepartment").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setDeprtments(obj.data);
          }
        })
    );
  };

  // Send save data
  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    console.log(typeof data.date);
    PostApiCall.postRequest(
      {
        userid: selectedStaff?.FLD_User_Id || userid,
        username: "",
        userpin: data.userpin,
        dept: data.dept || selectedStaff?.FLD_Dept,
        userType: data.userType || selectedStaff?.FLD_User_Type,
        name: data.name || selectedStaff?.FLD_Name,
        image: imageUrl || selectedStaff?.FLD_Image,
        usermobile: data.usermobile || selectedStaff?.FLD_Mobile_Number,
        designation: data.designation || selectedStaff?.FLD_Designation,
        date:
          data.date ||
          moment(selectedStaff?.FLD_Date_of_Joining).format("DD-MM-YY"),
        useraddress: data.useraddress || selectedStaff?.FLD_Address,
        pincode: data.pincode || selectedStaff?.FLD_Pincode,
        status: status ? "Active" : "Inactive",
      },
      "AddStaff"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("User registered sucessfully.");
          toggleForm(true); // render list - get staff list again
        } else {
          Notiflix.Notify.failure("User registration failed!");
        }
      })
    );
  };

  const toggleForm = (state, user) => {
    setShowList(state);
    setSelectedStaff(user);
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };

  // Handle Image on server
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload User Image
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "staffImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="margin-left">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-0 px-0">
          {!showList && (
            <div className="col-12 px-0">
              <div className="default-card default-card-border blank-section  ">
                <div className="card-heading d-flex justify-content-between align-items-center">
                  <h4>Add New User</h4>
                  <p className="mb-0">
                    Fields marked with <span className="text-danger">*</span>{" "}
                    are mandatory.
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-2 pb-3">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader w-100"
                      showUploadList={false}
                      customRequest={onaction}
                      beforeUpload={beforeUpload}
                    >
                      {imageUrl && (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: "100%",
                          }}
                        />
                      )}
                      {imageUrl === "" && selectedStaff?.FLD_Image && (
                        <img
                          src={selectedStaff?.FLD_Image}
                          alt="avatar"
                          style={{
                            width: "100%",
                          }}
                        />
                      )}
                      {imageUrl === "" &&
                        !selectedStaff?.FLD_Image &&
                        uploadButton}
                    </Upload>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-4 pb-3">
                        <label htmlFor="name">
                          Name<span className="text-danger">*</span>
                        </label>
                        <Input
                          size="large"
                          allowClear
                          className="input-box"
                          value={selectedStaff?.FLD_Name}
                          id="name"
                          {...register("name", {
                            required: selectedStaff?.FLD_Name
                              ? false
                              : "Name is required",
                          })}
                          onChange={handleChange("name")}
                        />
                        {errors.name && (
                          <p className="text-danger">{errors.name.message}</p>
                        )}
                      </div>
                      <div className="col-lg-4 pb-3">
                        <label htmlFor="userpin">
                          User Pin<span className="text-danger">*</span>
                        </label>
                        <Input
                          size="large"
                          placeholder="*****"
                          allowClear
                          className="input-box"
                          id="userpin"
                          {...register("userpin", {
                            required: "User pin is required",
                          })}
                          onChange={handleChange("userpin")}
                        />
                        {errors.userpin && (
                          <p className="text-danger">
                            {errors.userpin.message}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-4 pb-3">
                        <label htmlFor="dept">
                          User Department<span className="text-danger">*</span>
                        </label>
                        <Select
                          size="large"
                          className="input-box"
                          id="dept"
                          placeholder="Choose A Dept."
                          value={selectedStaff?.FLD_Dept}
                          onChange={(value) => {
                            setValue("dept", value);
                            clearErrors("dept");
                          }}
                        >
                          {departments.map((dept) => (
                            <Select.Option
                              key={dept.FLD_Department_Id}
                              value={dept.FLD_Department_Id}
                            >
                              {dept.FLD_Department_Name}
                            </Select.Option>
                          ))}
                        </Select>
                        {errors.dept && (
                          <p className="text-danger">{errors.dept.message}</p>
                        )}
                      </div>

                      <div className="col-lg-4 pb-3">
                        <label htmlFor="userType">
                          User Type<span className="text-danger">*</span>
                        </label>
                        <Select
                          size="large"
                          className="input-box"
                          id="userType"
                          value={selectedStaff?.FLD_User_Type}
                          placeholder="Choose A User Type"
                          onChange={(value) => {
                            setValue("userType", value);
                            clearErrors("userType");
                          }}
                        >
                          {userTypes.map((user) => (
                            <Select.Option
                              key={user.fld_id}
                              value={user.fld_id}
                            >
                              {user.FLD_UserType}
                            </Select.Option>
                          ))}
                        </Select>
                        {errors.userType && (
                          <p className="text-danger">
                            {errors.userType.message}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-4 pb-3">
                        <label htmlFor="usermobile">
                          Mobile Number<span className="text-danger">*</span>
                        </label>
                        <Input
                          size="large"
                          // placeholder="Mobile Number"
                          allowClear
                          className="input-box"
                          maxLength={10}
                          id="usermobile"
                          value={selectedStaff?.FLD_Mobile_Number}
                          {...register("usermobile", {
                            required: selectedStaff?.FLD_Mobile_Number
                              ? false
                              : "Mobile number is required",
                            pattern: {
                              value: /^\d{10}$/,
                              message: "usermobile must be 10 digits",
                            },
                          })}
                          onChange={handleChange("usermobile")}
                        />
                        {errors.usermobile && (
                          <p className="text-danger">
                            {errors.usermobile.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-4 pb-3">
                        <label htmlFor="designation">
                          Designation<span className="text-danger">*</span>
                        </label>
                        <Input
                          size="large"
                          // placeholder="Designation"
                          allowClear
                          className="input-box"
                          id="designation"
                          value={selectedStaff?.FLD_Designation}
                          {...register("designation", {
                            required: selectedStaff?.FLD_Designation
                              ? false
                              : "Designation is required",
                          })}
                          onChange={handleChange("designation")}
                        />
                        {errors.designation && (
                          <p className="text-danger">
                            {errors.designation.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-4 pb-3">
                        <label htmlFor="joiningDate">
                          Date of Joining<span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          id="joiningDate"
                          className="input-box"
                          format="DD-MM-YY"
                          // value={selectedStaff?.FLD_Date_of_Joining}
                          placeholder="Select Date Of Joining"
                          {...register("date", {
                            required: "Date of Joining is required",
                          })}
                          onChange={(date, dateString) => {
                            setValue("date", dateString);
                            clearErrors("date");
                          }}
                        />
                        {errors.date && (
                          <p className="text-danger">{errors.date.message}</p>
                        )}
                      </div>
                      <div className="col-lg-4 pb-3">
                        <label htmlFor="useraddress">
                          Address<span className="text-danger">*</span>
                        </label>
                        <TextArea
                          className="input-box input-box-textarea"
                          // placeholder="Address"
                          allowClear
                          value={selectedStaff?.FLD_Address}
                          id="useraddress"
                          {...register("useraddress", {
                            required: selectedStaff?.FLD_Address
                              ? false
                              : "Address is required",
                          })}
                          onChange={handleChange("useraddress")}
                        />
                        {errors.useraddress && (
                          <p className="text-danger">
                            {errors.useraddress.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-4 pb-3">
                        <label htmlFor="pincode">Pincode</label>
                        <Input
                          size="large"
                          // placeholder="Pincode"
                          allowClear
                          className="input-box"
                          value={selectedStaff?.FLD_Pincode}
                          id="pincode"
                          // {...register("pincode", {
                          //   validate: {
                          //     isNumeric: (value) =>
                          //       /^\d+$/.test(value) ||
                          //       "Pincode must be numeric",
                          //     isLength: (value) =>
                          //       value.length === 6 ||
                          //       "Pincode must be 6 digits long",
                          //   },
                          // })}
                          // onChange={handleChange("pincode")}
                          onChange={(e) => setValue("pincode", e.target.value)}
                        />
                        {/* {errors.pincode && (
                          <p className="text-danger">
                            {errors.pincode.message}
                          </p>
                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="btn-border mt-4 pt-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0">Inactive</p>
                        <Switch
                          onChange={handleStatusChange}
                          defaultChecked={
                            selectedStaff?.FLD_Status === "Active"
                          }
                        />
                        <p className="mb-0">Active</p>
                      </div>

                      <div>
                        <Button
                          className="default-btn border-0 mt-3"
                          onClick={() => {
                            toggleForm(true);
                            setImageUrl("");
                          }}
                        >
                          Go Back
                        </Button>
                        <Button
                          className="default-btn border-0 mt-3 ms-lg-4"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Save User
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* **** Staff Master List **** */}
          {showList && (
            // <StaffMasterList
            //   toggleForm={toggleForm}
            //   userTypes={userTypes}
            //   deptOptions={departments}
            // />
            <StaffMasterListNew
              toggleForm={toggleForm}
              userTypes={userTypes}
              deptOptions={departments}
            />
          )}
        </div>
      </div>
    </div>
  );
}
