import React from "react";
import SupervisorDashboard from "../SupervisorDashboard/SupervisorDashboard";
import GasSupervisor from "../SupervisorDashboard/GasSupervisor";
import AdminDashboard from "./AdminDashboard";
import RejectionDashboard from "../SupervisorDashboard/RejectionDashboard";

export const Dashboard = () => {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  let renderDash;
  console.log(login[0]?.FLD_User_Type);

  switch (login[0]?.FLD_User_Type) {
    case 3:
      renderDash = AdminDashboard;
      break;
    case 4:
      renderDash = GasSupervisor;
      break;
    case 5:
      renderDash = RejectionDashboard;
      break;
    default:
      renderDash = SupervisorDashboard;
      break;
  }

  return <>{React.createElement(renderDash)}</>;
};
